import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { NgRequest } from '../shared/ng-request';
import { NgResponse } from '../shared/ng-response';
import { HttpErrorHandler, HandleError, HandleBlobError } from './http-error-handler';

export abstract class HttpClientService {
  protected readonly handleError: HandleError;
  protected readonly handleBlobError: HandleBlobError;
  protected readonly jsonHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
  protected readonly jsonOptions = { headers: this.jsonHeaders };

  protected constructor(
    readonly service: string,
    readonly httpClient: HttpClient,
    readonly httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createErrorHandler(service);
    this.handleBlobError = httpErrorHandler.createBlobErrorHandler(service);
  }

  protected delete<TResponse>(operation: string, urlPath: string): Observable<NgResponse<TResponse>> {
    return this.httpClient.delete<NgResponse<TResponse>>(urlPath, this.jsonOptions)
      .pipe(catchError(this.handleError<TResponse>(operation)));
  }

  protected get<TResponse>(operation: string, urlPath: string): Observable<NgResponse<TResponse>> {
    return this.httpClient.get<NgResponse<TResponse>>(urlPath, this.jsonOptions)
      .pipe(catchError(this.handleError<TResponse>(operation)));
  }

  protected getBlobResponse(operation: string, urlPath: string): Observable<HttpResponse<Blob>> {
    return this.httpClient.get(urlPath, { headers: this.jsonHeaders, observe: 'response', responseType: 'blob' })
      .pipe(catchError(this.handleBlobError(operation)));
  }

  protected post<TResponse>(operation: string, urlPath: string): Observable<NgResponse<TResponse>> {
    return this.httpClient.post<NgResponse<TResponse>>(urlPath, null, this.jsonOptions)
      .pipe(catchError(this.handleError<TResponse>(operation)));
  }

  protected postBody<TRequest, TResponse>(operation: string, urlPath: string, request: NgRequest<TRequest>): Observable<NgResponse<TResponse>> {
    return this.httpClient.post<NgResponse<TResponse>>(urlPath, request, this.jsonOptions)
      .pipe(catchError(this.handleError<TResponse>(operation)));
  }

  protected postBodyWithBlobResponse<TRequest>(operation: string, urlPath: string, request: NgRequest<TRequest>): Observable<HttpResponse<Blob>> {
    return this.httpClient.post(urlPath, request, { headers: this.jsonHeaders, observe: 'response', responseType: 'blob' })
      .pipe(catchError(this.handleBlobError(operation)));
  }

  protected postFile<TResponse>(operation: string, urlPath: string, formData: FormData): Observable<NgResponse<TResponse>> {
    return this.httpClient.post<NgResponse<TResponse>>(urlPath, formData)
      .pipe(catchError(this.handleError<TResponse>(operation)));
  }

  protected postFileWithBlobResponse(operation: string, urlPath: string, formData: FormData): Observable<HttpResponse<Blob>> {
    return this.httpClient.post(urlPath, formData, { observe: 'response', responseType: 'blob' })
      .pipe(catchError(this.handleBlobError(operation)));
  }

  protected put<TResponse>(operation: string, urlPath: string): Observable<NgResponse<TResponse>> {
    return this.httpClient.put<NgResponse<TResponse>>(urlPath, null, this.jsonOptions)
      .pipe(catchError(this.handleError<TResponse>(operation)));
  }

  protected putBody<TRequest, TResponse>(operation: string, urlPath: string, request: NgRequest<TRequest>): Observable<NgResponse<TResponse>> {
    return this.httpClient.put<NgResponse<TResponse>>(urlPath, request, this.jsonOptions)
      .pipe(catchError(this.handleError<TResponse>(operation)));
  }
}
