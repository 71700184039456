import { IFCSeverities, IFCSeverityCodes, IFCSeverityNames } from './ifc-severities';
import * as moment from 'moment';
import { dateConvert, dateTimeConvert } from './date-operations';
import { CDSCodeTypes } from './cds-code-types';
import { GlobalConstants } from './global-constants';
import { LEASelfCertStatuses } from './lea-self-cert-statuses';
import { LEASelfCertStatusNames } from './lea-self-cert-status-names';
import { OversightReviewStatuses } from './oversight-review-statuses';
import { OversightReviewStatusNames } from './oversight-review-status-names';
import { ReportingPeriods } from './reporting-periods';
import { ReportingPeriodNameRanks, ReportingPeriodNames } from './reporting-period-names';
import { TRCOutcome } from './trc-outcome';
import { TRCOutcomeNames } from './trc-outcome-names';
import { Entity } from './entity.model';
import { EntityTypes } from './entity-types';
import { EntityTypeNames } from './entity-type-names';
import { EntityTypeRankNames } from './entity-type-rank-names';
import { EntitySubtypeDefinitions } from './entity-subtype-definitions';
import { EntitySubtypeNames } from './entity-subtype-names';
import { CharterTypes } from './charter-types';
import { CharterTypeNames } from './charter-type-names';
import { AccountStatuses } from './account-statuses';
import { PasswordStatuses } from './password-statuses';
import { AccountStatusNames } from './account-status-names';
import { PasswordStatusNames } from './password-status-names';
import { ColumnCodes } from './column-codes';
import { ColumnCodeNames, ColumnCodeNameRanks } from './column-code-names';
import { CharterReviewStatusNames } from './charter-review-status-names';
import { CharterReviewStatuses } from './charter-review-statuses';
import { DatasetTypes } from './dataset-types';
import { DatasetTypeNames } from './dataset-type-names';
import { FiscalYearStatusNames } from './fiscal-year-status-names';
import { FiscalYearStatus } from './fiscal-year-status';
import { TRCSeverityCodes, TRCSeverityNames } from './trc/trc-severity';
import { SubmissionStates } from './submission-states';
import { StateListMap } from './states-list-map';
import { TRCEvaluationTargets } from './trc-evaluation-targets';
import { TRCEvaluationTargetNames } from './trc-evaluation-target-names';
import { TRCExplanationModeNames, TRCExplanationModes } from './trc/trc-model';
import { EntityRelationshipTypes, EntityRelationshipTypeNames } from './entity-relationship-types';
import { UserGLDeterminations } from './user-gl-determinations';
import { UserGLDeterminationTexts } from './user-gl-determination-texts';

export const fieldof = <T>(key: keyof T) => key;

export function localeCompare(string1: string, string2: string, emptySameAsNull: boolean = false): number {
  if (!string1 || (emptySameAsNull && string1.length === 0)) {
    return !string2 || (emptySameAsNull && string2.length === 0) ? 0 : -1;
  }
  return !string2 || (emptySameAsNull && string2.length === 0) ? +1 : string1.localeCompare(string2);
}

export function formatCDSCode(cdsCode: string): string {
  return cdsCode
    ? `${cdsCode.slice(0, 2)}-${cdsCode.slice(2, 7)}-${cdsCode.slice(7)}`
    : '';
}

export function dateFormat(value: string | number | Date, format?: string): string {
  value = dateConvert(value);
  return value
    ? moment(value).format(format || 'MM-DD-YY')
    : '(none)';
}

export function timeFormat(value: string | number | Date, format?: string): string {
  value = dateTimeConvert(value);
  return value
    ? moment(value).format(format || 'HH:mm:ss')
    : '(none)';
}

export function dateTimeFormat(value: string | number | Date): string {
  if (value) {
    const dateValue = new Date(value).getTime();
    if (!isNaN(dateValue) && dateValue !== GlobalConstants.minEpoch) {
      return moment(dateValue).format('MM-DD-YY HH:mm');
    }
  }
  return '(none)';
}

export function emptyStringFormat(): string { return ''; }
export function stringFormat(value: string): string { return value ? value : ''; }
export function stringFormat2Decimals(value: string): string { return value ? (+value).toFixed(2) : ''; }
export function numberFormat2Decimals(value: number): string { return value ? (+value).toFixed(2) : '0.00'; }
export function booleanCheckedUncheckedFormat(value: boolean): string { return value ? 'Checked' : 'Unchecked'; }
export function booleanYesNoFormat(value: boolean): string { return value ? 'Yes' : value === false ? 'No' : ''; }
export function booleanYesNoNotEmptyFormat(value: boolean): string { return value ? 'Yes' : 'No' }
export function booleanApprovedDisapproved(value: boolean): string { return value ? 'Approved' : value === false ? 'Disapproved' : ''; }

export function entityFormat(entity: Entity) {
  return !entity ? '(none)' : `${formatCDSCode(entity.cdsCode)} ${entity.name}`;
}

export function accountStatusFormat(value: AccountStatuses): string {
  const accountStatusNames = new AccountStatusNames();
  return (value && accountStatusNames[value]) || '';
}

export function passwordStatusFormat(value: PasswordStatuses): string {
  const passwordStatusNames = new PasswordStatusNames();
  return (value && passwordStatusNames[value]) || '';
}

export function getLEASelfCertStatusLabel(value: LEASelfCertStatuses): string {
  const leaSelfCertStatusNames = new LEASelfCertStatusNames();
  return (value && leaSelfCertStatusNames[value]) || '';
}

export function getOversightStatusLabel(value: OversightReviewStatuses): string {
  const oversightReviewStatusNames = new OversightReviewStatusNames();
  return (value && oversightReviewStatusNames[value]) || '';
}

export function getReportingPeriodLabel(value: ReportingPeriods): string {
  const reportingPeriodNames = new ReportingPeriodNames();
  return (value && reportingPeriodNames[value]) || '';
}

export function getReportingPeriodLabelFromRank(rank: number): string {
  const reportingPeriodNameRanks = new ReportingPeriodNameRanks();
  return (rank && reportingPeriodNameRanks[rank]) || '';
}

export function getColumnCodeLabel(value: ColumnCodes): string {
  const columnCodeNames = new ColumnCodeNames();
  return (value && columnCodeNames[value]) || '';
}

export function getColumnCodeLabelFromRank(rank: number): string {
  const columnCodeNameRanks = new ColumnCodeNameRanks();
  return (rank && columnCodeNameRanks[rank]) || '';
}

export function getEntityTypeLabel(value: EntityTypes): string {
  const entityTypeNames = new EntityTypeNames();
  return (value && entityTypeNames[value]) || '';
}

export function getUserGLDeterminationLabel(value: UserGLDeterminations): string {
  const userGLDeterminationTexts = new UserGLDeterminationTexts();
  return (value && userGLDeterminationTexts[value]) || '';
}

export function getEntityTypeRankLabel(value: number): string {
  const entityRankNames = new EntityTypeRankNames();
  return (value && entityRankNames[value]) || '';
}

export function getEntitySubtypeLabel(value: EntitySubtypeDefinitions): string {
  const entitysubtypeNames = new EntitySubtypeNames();
  return (value && entitysubtypeNames[value]) || '';
}

export function getCharterTypeLabel(value: CharterTypes): string {
  const charterTypeNames = new CharterTypeNames();
  return (value && charterTypeNames[value]) || '';
}

export function getTRCOutcomeLabel(value: TRCOutcome): string {
  const trcOutcomeNames = new TRCOutcomeNames();
  return (value && trcOutcomeNames[value]) || '';
}

export function getCharterReviewStatusLabel(value: CharterReviewStatuses): string {
  const charterReviewStatusNames = new CharterReviewStatusNames();
  return (value && charterReviewStatusNames[value]) || '';
}

export function getDatasetTypeLabel(value: DatasetTypes): string {
  const dataSetTypeNames = new DatasetTypeNames();
  return (value && dataSetTypeNames[value]) || '';
}

export function getIFCSeverityLabel(value: IFCSeverities): string {
  const ifcSeverityNames = new IFCSeverityNames();
  return (value && ifcSeverityNames[value]) || '';
}

export function getIFCSeverityCode(value: IFCSeverities): string {
  const ifcSeverityCodes = new IFCSeverityCodes();
  return (value && ifcSeverityCodes[value]) || '';
}

export function getTRCSeverityLabel(value: IFCSeverities): string {
  const trcSeverityNames = new TRCSeverityNames();
  return (value && trcSeverityNames[value]) || '';
}

export function getTRCSeverityCode(value: IFCSeverities): string {
  const trcSeverityCodes = new TRCSeverityCodes();
  return (value && trcSeverityCodes[value]) || '';
}

export function getEntityRelationshipTypesLabel(value: EntityRelationshipTypes): string {
  const entityRelationshipTypeNames = new EntityRelationshipTypeNames();
  return (value && entityRelationshipTypeNames[value]) || '';
}

export function parseCDSCode(cdsCode: string, cdsCodeType: CDSCodeTypes): string {
  switch (cdsCodeType) {
    case CDSCodeTypes.County:
      return cdsCode.substring(0, 2);
    case CDSCodeTypes.District:
      return cdsCode.substring(2, 7);
    case CDSCodeTypes.School:
      return cdsCode.substring(7, 14);
    default:
      return '';
  }
}

export function getPropertyValue(obj: any, propertyPath: string): any {
  return propertyPath.split('.').reduce((acc, curr) => acc && acc[curr], obj);
}

export function capitalize(str: string): string {
  return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
}

export function camelize(str: string, separator: string = '.'): string {
  return str.split(separator).reduce((acc, curr, index) => `${acc}${index === 0 ? curr : capitalize(curr)}`, '');
}

export function getFiscalYearStatusLabel(value: FiscalYearStatus): string {
  const fiscalYearStatusNames = new FiscalYearStatusNames();
  return (value && fiscalYearStatusNames[value]) || '';
}

export function trimStartChars(value: string, chars: string) {
  if (chars === undefined)
    chars = '\s';

  return value.replace(new RegExp(`^[${chars}]+`), '');
}

export function trimEndChars(value: string, chars: string) {
  if (chars === undefined)
    chars = '\s';

  return value.replace(new RegExp(`[${chars}]+$`), '');
}

export function trimChars(value: string, chars: string) {
  const result = trimStartChars(value, chars);
  return trimEndChars(result, chars);
}

export function getSubmissionStateCDEDisplayLabel(value: SubmissionStates) {
  const stateListMap = new StateListMap();
  return (value && stateListMap[value] && stateListMap[value].cdeDisplayLabel) || '';
}

export function getTRCEvaluationTargetLabel(value: TRCEvaluationTargets): string {
  const trcEvaluationTargetNames = new TRCEvaluationTargetNames();
  return (value && trcEvaluationTargetNames[value]) || '';
}

export function formatCommaSeparatedList(values: Array<string>): string {
  return values ? values.join(', ') : '';
}

export function getTRCExplanationModeLabel(value: TRCExplanationModes): string {
  const trcExplanationModeNames = new TRCExplanationModeNames();
  return (value && trcExplanationModeNames[value]) || '';
}
