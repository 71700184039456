import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AppResolver } from './app.resolver';

const appRoutes: Routes = [{
  path: '', resolve: { app: AppResolver },
  children: [
    { path: '', redirectTo: '/security/login', pathMatch: 'full' },
    { path: 'queues', loadChildren: () => import('./queues/queues.module').then(m => m.QueuesModule) },
    { path: 'reports', loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule) },
    { path: 'security', loadChildren: () => import('./security/security.module').then(m => m.SecurityModule) },
    //{ path: 'dashboard', loadChildren: './dashboard/dashboard.module#DashboardModule' },
    { path: 'dashboard', loadChildren: () => import('./submissions/submissions.module').then(m => m.SubmissionsModule) },
    { path: 'submission', loadChildren: () => import('./submission/submission.module').then(m => m.SubmissionModule) },
    { path: 'submissions', loadChildren: () => import('./submissions/submissions.module').then(m => m.SubmissionsModule) },
    { path: 'tools', loadChildren: () => import('./tools/tools.module').then(m => m.ToolsModule) },
    { path: 'coversheets', loadChildren: () => import('./coversheet60k/coversheet60k.module').then(m => m.Coversheet60kModule) },
  ]
}];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
