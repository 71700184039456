import { Injectable } from '@angular/core'
import { Message, MessageService } from 'primeng/api';
import { IFCSeverities } from '../shared/ifc-severities';
import { NgSeverityLevels } from '../shared/ng-severity-levels';

@Injectable()
export class AppMessageService {
  constructor(readonly messageService: MessageService) {}  

  add(message: Message) {
    message.severity = this.convertSeverity(message.severity);
    this.messageService.add(message);
  }

  clear(key?: string) {
    this.messageService.clear(key);
  }

  private convertSeverity(severity: string): string {
    switch (severity) {
      case IFCSeverities.Fatal:
      case NgSeverityLevels.Error:
        return "error";
      case IFCSeverities.Warning:
      case NgSeverityLevels.Warn:
        return "warn";
      case IFCSeverities.NoSeverity:
      case NgSeverityLevels.Info:
        return "info";
      case NgSeverityLevels.Success:
        return "success";
      default:
        return severity.toLowerCase();
    }
  }
}
