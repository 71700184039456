<div class="layout-sidebar" (click)="onMenuClick($event)" (mouseenter)="onMenuMouseEnter($event)" (mouseleave)="onMenuMouseLeave($event)"
     role="navigation" aria-label="Left navigation">

  <div class="layout-sidebar-logo">
    <a id="sacs-logomark" [routerLink]="['/dashboard']" aria-label="main page" tabindex="0">
    </a>
    <a id="layout-sidebar-anchor" class="layout-sidebar-anchor" title="Expand or collapse sidebar" (click)="onAnchorClick($event)" tabindex="0">
      <i class="pi"></i>
    </a>
  </div>

  <p-scrollPanel #layoutMenuScroller [style]="{height: '100%'}">
    <div class="sidebar-scroll-content">
      <div class="layout-menu-container">
        <div id="sidebar-header" class="menu-header-section">
          <div class="grid">
            <ng-container *ngTemplateOutlet="headerButtons"></ng-container>
            <ng-container *ngTemplateOutlet="headerContent"></ng-container>
            <div class="col-12 period-lea-cds">
              <input class="sidebar-search" pInputText type="text" placeholder="{{filterPlaceholder}}"
                     (keyup)="filterMenuItems($event)" [(ngModel)]="filterInput" />
              <h4 *ngIf="allItemsHidden">No Matches Found</h4>
            </div>
          </div>
        </div>
        <ul app-left-submenu [items]="model" [reset]="reset" class="layout-menu" visible="true"></ul>
      </div>
    </div>
  </p-scrollPanel>
</div>
