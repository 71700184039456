import { EntitySubtypeDefinitions } from './entity-subtype-definitions';

export class EntitySubtypeNames {
  [entitySubtype: string]: string;

  constructor() {
    this[EntitySubtypeDefinitions.LEAType] = 'LEA Type';
    this[EntitySubtypeDefinitions.DistrictType] = 'District Type';
    this[EntitySubtypeDefinitions.SchoolType] = 'School Type';
    this[EntitySubtypeDefinitions.CharterType] = 'Charter Type';
  }
}
