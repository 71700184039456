import { Injectable } from '@angular/core';
import { AppContext } from '../app.context';

@Injectable({
  providedIn: 'root'
})
export class AppInsightsService {
  constructor(private appContext: AppContext) { }

  logPageView(name?: string, url?: string) {
    this.appContext.appInsights?.trackPageView({ name: name, uri: url });
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    this.appContext.appInsights?.trackEvent({ name: name }, properties);
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    this.appContext.appInsights?.trackMetric({ name: name, average: average }, properties);
  }

  logException(exception: Error, severityLevel?: number) {
    this.appContext.appInsights?.trackException({ exception: exception, severityLevel: severityLevel });
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    this.appContext.appInsights?.trackTrace({ message: message }, properties);
  }
}
