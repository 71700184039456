import { ColumnCodes } from './column-codes';

export class ColumnCodeNames {
  [columnCode: string]: string;

  constructor() {
    this[ColumnCodes.Any] = 'Any';
    this[ColumnCodes.Unknown] = 'Unknown';
    this[ColumnCodes.BB] = 'Budget';
    this[ColumnCodes.BE] = 'Estimated Actuals';
    this[ColumnCodes.BA] = 'Unaudited Actuals';
    this[ColumnCodes.IO] = 'Original Budget';
    this[ColumnCodes.IB] = 'Board Approved Operating Budget';
    this[ColumnCodes.IA] = 'Actuals to Date';
    this[ColumnCodes.IP] = 'Projected Totals';
  }
}

export class ColumnCodeNameRanks {
  [rank: number]: string;

  constructor() {
    this[0] = 'Actuals to Date';
    this[1] = 'Board Approved Operating Budget';
    this[2] = 'Budget';
    this[3] = 'Estimated Actuals';
    this[4] = 'Original Budget';
    this[5] = 'Projected Totals';
    this[6] = 'Unaudited Actuals';
  }
}
