import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { NgRequest } from '../shared/ng-request';
import { PrintRequest } from '../shared/print.model';

import { HttpClientService } from './http-client.service';
import { HttpErrorHandler } from './http-error-handler';
import { Observable } from 'rxjs';

@Injectable()
export class PrintService extends HttpClientService {
  constructor(
    readonly httpClient: HttpClient,
    readonly httpErrorHandler: HttpErrorHandler) {
    super('PrintService', httpClient, httpErrorHandler);
  }

  printPreview(printRequest: NgRequest<PrintRequest>): Observable<HttpResponse<Blob>> {
    return this.postBodyWithBlobResponse<PrintRequest>('printPreview', 'api/Print/Preview', printRequest);
  }

  exportExcel(printRequest: NgRequest<PrintRequest>): Observable<HttpResponse<Blob>> {
    return this.postBodyWithBlobResponse<PrintRequest>('exportExcel', 'api/Print/Export', printRequest);
  }
}
