export type EnumValueType = string | number;

export interface IEnumNameValueMap { }

export class StringEnumNameValueMap implements IEnumNameValueMap {
  [value: string]: string;
}

export class NumericEnumNameValueMap implements IEnumNameValueMap {
  [value: number]: string;
}

export class Enum {
  private constructor() { }

  static getStringNameValueMap(e: any, prefix?: string): StringEnumNameValueMap {
    const nameValueMap = new StringEnumNameValueMap();
    this.fillNameValueMap(e, prefix, nameValueMap);
    return nameValueMap;
  }

  static getNumericNameValueMap(e: any, prefix?: string): NumericEnumNameValueMap {
    const nameValueMap = new NumericEnumNameValueMap();
    this.fillNameValueMap(e, prefix, nameValueMap);
    return nameValueMap;
  }

  private static fillNameValueMap<TNameValueMap extends IEnumNameValueMap>(e: any, prefix: string, nameValueMap: TNameValueMap): void {
    Object.keys(e).forEach(name => {
      if (!prefix || name.startsWith(prefix)) {
        nameValueMap[e[name]] = name;
      }
    });
  }

  static getNamesAndValues<T extends EnumValueType>(e: any): Array<{ name: string, value: T }> {
    return this.getNames(e).map(name => {
      return {
        name: name, value: e[name] as T
      };
    });
  }

  static getNames(e: any): Array<string> {
    return Object.keys(e).filter(key => isNaN(+key));
  }

  static getName<T extends EnumValueType>(e: any, value: T): string | null {
    const all = this.getNamesAndValues(e).filter(pair => pair.value === value);
    return all.length === 1 ? all[0].name : null;
  }

  static getValues<T extends EnumValueType>(e: any): Array<T> {
    return this.getNames(e).map(name => e[name]) as Array<T>;
  }
}
