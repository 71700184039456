import { TRCEvaluationTargets } from './trc-evaluation-targets';

export class TRCEvaluationTargetNames {
  [target: string]: string;

  constructor() {
    this[TRCEvaluationTargets.UserData] = 'User Data';
    this[TRCEvaluationTargets.SingleString] = 'Single String';
  }
}
