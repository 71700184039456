import { LoggedInUserService } from './loggedInUser.service';
import { Injectable } from '@angular/core';
import { Observable, Subject, interval, Subscription } from 'rxjs';
import { AppContext } from "../app.context";

@Injectable({
  providedIn: 'root'
})
export class SessionTimerService {
  private readonly _timeoutSeconds: number;
  private _count: number;
  private timerSubscription: Subscription;
  private timer: Observable<number> = interval(1000);
  private _remainSeconds = new Subject<number>();
  remainSeconds$ = this._remainSeconds.asObservable();
  private readonly sessionExpiryCounterStorageKey = 'SACS:AppContext:SessionExpiryCounter';

  constructor(readonly appContext: AppContext, readonly loggedInUserService: LoggedInUserService) {
    this._timeoutSeconds = this.appContext.sessionTimeout;
  }

  startTimer() {
    if (this.appContext.loggedInUser) {
      this.stopTimer();
      localStorage.setItem(this.sessionExpiryCounterStorageKey, this._timeoutSeconds.toString());
      this.timerSubscription = this.timer.subscribe(n => {
        if(!this.loggedInUserService.isLoggedIn) this._remainSeconds.next(0);
        this._count = Number(localStorage.getItem(this.sessionExpiryCounterStorageKey))
        if (this._count > 0)
          this._count--;
        localStorage.setItem(this.sessionExpiryCounterStorageKey, this._count.toString());
        this._remainSeconds.next(this._count);
      });
    }
  }

  stopTimer() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }

  resetTimer() {
    localStorage.setItem(this.sessionExpiryCounterStorageKey, this._timeoutSeconds.toString());
    this.startTimer();
  }

  continueSession() {
    this.resetTimer();
  }

  stopSession() {
    this.stopTimer();
    localStorage.removeItem(this.sessionExpiryCounterStorageKey);
  }
}
