import { ReportService } from './services/report.service';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ConfirmationService } from 'primeng/api';
import { TreeModule } from 'primeng/tree';

import { CookieService } from 'ngx-cookie-service';

import * as moment from 'moment';

import { SharedModule } from './shared.module';
import { AppRoutingModule } from './app-routing.module';
import { NavigationModule } from './navigation/navigation.module';

import { AppContext } from './app.context';
import { AppResolver } from './app.resolver';
import { AppComponent } from './app.component';

import { ManhattenthemeModule } from './manhattentheme/manhattentheme.module';

import { UserService } from './services/user.service';
import { PrintService } from './services/print.service';
import { TitleService } from './services/title.service';
import { SessionTimerService } from './services/session-timer.service';
import { EntityService } from './services/entity.service';
import { ComputeService } from './services/compute.service';
import { SecurityService } from './services/security.service';
import { HttpErrorHandler } from './services/http-error-handler';
import { SubmissionService } from './services/submission.service';
import { AppInsightsService } from './services/app-insights.service';
import { LoggedInUserService } from './services/loggedInUser.service';
import { ReferenceDataService } from './services/reference-data.service';
import { StateDataService } from './services/state-data.service';
import { UserDataService } from './services/user-data.service';
import { BatchService } from './services/batch.service';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { TimeoutInterceptor } from './services/timeout.interceptor';
import { DataService } from "./services/data.service";
import { FormDependencyService } from "./services/form-dependency.service";

@NgModule({
  declarations: [
    AppComponent
  ],
  bootstrap: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    AppRoutingModule,
    NavigationModule,
    HttpClientModule,
    TreeModule,
    ManhattenthemeModule,
    DynamicDialogModule
  ],
  exports: [
  ],
  providers: [
    {
      provide: 'moment',
      useFactory: (): any => moment
    },
    AppContext,
    AppResolver,
    UserService,
    BatchService,
    PrintService,
    TitleService,
    SessionTimerService,
    CookieService,
    EntityService,
    ComputeService,
    SecurityService,
    ReportService,
    HttpErrorHandler,
    SubmissionService,
    AppInsightsService,
    ConfirmationService,
    LoggedInUserService,
    ReferenceDataService,
    StateDataService,
    DialogService,
    UserDataService,
    DataService,
    FormDependencyService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TimeoutInterceptor,
      multi: true
    }
  ]
})
export class AppModule {
  constructor() { }
}
