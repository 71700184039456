import { ColumnCodes } from "./column-codes";
import { ReportingPeriods } from "./reporting-periods";

export class DefaultColumnCodesMap {
  [reportingPeriod: string]: ColumnCodes;

  constructor() {
    this[ReportingPeriods.Any] = ColumnCodes.Any;
    this[ReportingPeriods.A] = ColumnCodes.BA;
    this[ReportingPeriods.BS1] = ColumnCodes.BB;
    this[ReportingPeriods.I1] = ColumnCodes.IP;
    this[ReportingPeriods.I2] = ColumnCodes.IP;
    this[ReportingPeriods.I3] = ColumnCodes.IP;
    this[ReportingPeriods.Unknown] = ColumnCodes.Unknown;
  }
}
