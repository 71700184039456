export enum EntityTypes {
  Any = 'Any',
  CaliforniaDepartmentOfEducation = 'CaliforniaDepartmentOfEducation',
  CharterSchool = 'CharterSchool',
  CountyOfficeOfEducation = 'CountyOfficeOfEducation',
  JointPowersAgency = 'JointPowersAgency',
  SchoolDistrict = 'SchoolDistrict',
  StateBoardOfEducation = 'StateBoardOfEducation',
  Unknown = 'Unknown'
}
