import { CommonModule } from '@angular/common';
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgPipesModule } from "ng-pipes";
import { NgxExtendedPdfViewerModule } from "ngx-extended-pdf-viewer";
import { ConfirmationService, MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ListboxModule } from 'primeng/listbox';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PanelModule } from 'primeng/panel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SpinnerModule } from 'primeng/spinner';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TableModule } from "primeng/table";
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { TreeTableModule } from 'primeng/treetable';
import { BaseComponent } from './base.component';
import { DisplayLocksComponent } from './locking/display-locks/display-locks.component';
import { LocksContext } from './locks.context';
import { MessagesComponent } from './messages/messages.component';
import { AppMessageService } from './services/app-message.service';
import { FormProcessor } from './services/form.processor';
import { LocksService } from './services/locks.service';
import { MinusSignToParens } from "./services/minus-sign-to-parens.pipe";
import { SessionAlertComponent } from './session-alert/session-alert.component';
import { BatchDialogComponent } from './shared/batch-dialog/batch-dialog.component';
import { BatchDialogService } from './services/batch-dialog.service';
import { ComponentVersionDataComponent } from './shared/component-version-data/component-version-data.component';
import { DescriptionComponent } from './shared/description/description.component';
import { ALTFormViewerComponent } from './shared/forms/alt-form-viewer/alt-form-viewer.component';
import { CATFormViewerComponent } from './shared/forms/cat-form-viewer/cat-form-viewer.component';
import { CEFBFormViewerComponent } from './shared/forms/cefb-form-viewer/cefb-form-viewer.component';
import { FormViewerComponent } from "./shared/forms/form-viewer/form-viewer.component";
import { FrozenColumnDirective } from './shared/forms/form-viewer/frozen-column.directive';
import { MYPIOFormViewerComponent } from './shared/forms/mypio-form-viewer/mypio-form-viewer.component';
import { PGMFormViewerComponent } from './shared/forms/pgm-form-viewer/pgm-form-viewer.component';
import { SEMAFormViewerComponent } from './shared/forms/sema-form-viewer/sema-form-viewer.component';
import { GridComponent } from "./shared/grid/grid.component";
import { MultiSelectChangeComponent } from './shared/grid/multiselect-change/multiselect-change.component';
import { HelpLinkComponent } from './shared/help-link/help-link.component';
import { PrintDialogComponent } from './shared/printing/print-dialog/print-dialog.component';
import { PrintContext } from './shared/printing/print.context';
import { ReportDownloadComponent } from './shared/report-download/report-download.component';
import { StateDropdownComponent } from "./shared/state-dropdown/state-dropdown.component";
import { TermsOfServiceComponent } from "./shared/terms-of-service/terms-of-service.component";
import { SubmissionContext } from './submission/submission.context';
import { StatewideBatchOperationComponent } from './shared/statewide-batch-operation/statewide-batch-operation.component';

@NgModule({
  declarations: [
    BaseComponent,
    MessagesComponent,
    SessionAlertComponent,
    GridComponent,
    StateDropdownComponent,
    FormViewerComponent,
    MinusSignToParens,
    TermsOfServiceComponent,
    MultiSelectChangeComponent,
    CEFBFormViewerComponent,
    HelpLinkComponent,
    CATFormViewerComponent,
    ALTFormViewerComponent,
    SEMAFormViewerComponent,
    BatchDialogComponent,
    DisplayLocksComponent,
    ReportDownloadComponent,
    MYPIOFormViewerComponent,
    PGMFormViewerComponent,
    PrintDialogComponent,
    DescriptionComponent,
    ComponentVersionDataComponent,
    FrozenColumnDirective,
    StatewideBatchOperationComponent
  ],
  imports: [
    CommonModule,
    MessageModule,
    MessagesModule,
    TableModule,
    RadioButtonModule,
    DropdownModule,
    MultiSelectModule,
    FormsModule,
    OverlayPanelModule,
    ButtonModule,
    ListboxModule,
    RouterModule,
    CheckboxModule,
    PanelMenuModule,
    TabViewModule,
    ProgressSpinnerModule,
    TreeTableModule,
    CalendarModule,
    NgPipesModule,
    SpinnerModule,
    NgxExtendedPdfViewerModule,
    DialogModule,
    PanelModule,
    ScrollPanelModule,
    SplitButtonModule,
    ConfirmDialogModule,
    InputMaskModule,
    TooltipModule,
    InputNumberModule,
    InputTextModule,
    InputTextareaModule,
    CardModule,
    ToastModule,
    DynamicDialogModule,
    TreeModule
  ],
  exports: [
    BaseComponent,
    MessagesComponent,
    SessionAlertComponent,
    GridComponent,
    StateDropdownComponent,
    FormViewerComponent,
    MinusSignToParens,
    DialogModule,
    CalendarModule,
    NgPipesModule,
    TreeTableModule,
    ProgressSpinnerModule,
    TabViewModule,
    OverlayPanelModule,
    SpinnerModule,
    NgxExtendedPdfViewerModule,
    TermsOfServiceComponent,
    CEFBFormViewerComponent,
    MultiSelectChangeComponent,
    HelpLinkComponent,
    CATFormViewerComponent,
    ALTFormViewerComponent,
    SEMAFormViewerComponent,
    DisplayLocksComponent,
    ReportDownloadComponent,
    MYPIOFormViewerComponent,
    PGMFormViewerComponent,
    InputTextareaModule,
    ListboxModule,
    CheckboxModule,
    DropdownModule,
    PrintDialogComponent,
    DescriptionComponent,
    ComponentVersionDataComponent,
    FrozenColumnDirective,
    MultiSelectModule,
    TreeModule,
    StatewideBatchOperationComponent
  ],
  providers: [
    MessageService,
    AppMessageService,
    FormProcessor,
    ConfirmationService,
    LocksContext,
    LocksService,
    SubmissionContext,
    PrintContext,
    BatchDialogService
  ]
})
export class SharedModule {
  constructor() { }
}
