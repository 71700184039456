import { PasswordStatuses } from './password-statuses';

export class PasswordStatusNames {
  [passwordStatus: string]: string;

  constructor() {
    this[PasswordStatuses.Active] = 'Active';
    this[PasswordStatuses.Expired] = 'Expired';
    this[PasswordStatuses.ExpiresSoon] = 'Expires Soon';
    this[PasswordStatuses.Locked] = 'Locked';
    this[PasswordStatuses.New] = 'New';
  }
}
