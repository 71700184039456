import { GlobalConstants } from './global-constants';

export function dateConvert(value: string | number | Date): Date {
  if (value) {
    const dateValue = new Date(value);
    const dateNumber = dateValue.getTime();
    if (!isNaN(dateNumber) &&
      dateNumber !== GlobalConstants.minEpoch) {
      return new Date(
        dateValue.getFullYear(),
        dateValue.getMonth(),
        dateValue.getDate());
    }
  }
  return null;
}

export function dateTimeConvert(value: string | number | Date): Date {
  if (value) {
    const dateValue = new Date(value);
    const dateNumber = dateValue.getTime();
    if (!isNaN(dateNumber) &&
      dateNumber !== GlobalConstants.minEpoch) {
      return new Date(
        dateValue.getFullYear(),
        dateValue.getMonth(),
        dateValue.getDate(),
        dateValue.getHours(),
        dateValue.getMinutes(),
        dateValue.getSeconds());
    }
  }
  return null;
}

export function dateConvertUTC(value: string | number | Date): Date {
  if (value) {
    const dateValue = new Date(value);
    const dateNumber = dateValue.getTime();
    if (!isNaN(dateNumber) &&
      dateNumber !== GlobalConstants.minEpoch) {
      return new Date(Date.UTC(
        dateValue.getUTCFullYear(),
        dateValue.getUTCMonth(),
        dateValue.getUTCDate()));
    }
  }
  return null;
}

export function dateTimeConvertUTC(value: string | number | Date): Date {
  if (value) {
    const dateValue = new Date(value);
    const dateNumber = dateValue.getTime();
    if (!isNaN(dateNumber) &&
      dateNumber !== GlobalConstants.minEpoch) {
      return new Date(Date.UTC(
          dateValue.getUTCFullYear(),
          dateValue.getUTCMonth(),
          dateValue.getUTCDate(),
          dateValue.getUTCHours(),
          dateValue.getUTCMinutes(),
          dateValue.getUTCSeconds(),
          dateValue.getUTCMilliseconds()));
    }
  }
  return null;
}

export function dateConvertLocal(utcValue: string | number | Date): Date {
  if (utcValue) {
    let dateValue = new Date(utcValue);
    const dateNumber = dateValue.getTime();
    const timeZoneOffset = dateValue.getTimezoneOffset();
    if (!isNaN(dateNumber) &&
      !isNaN(timeZoneOffset) &&
      dateNumber !== GlobalConstants.minEpoch) {
      dateValue = new Date(dateNumber - (timeZoneOffset * 60 * 1000));
      return new Date(
        dateValue.getFullYear(),
        dateValue.getMonth(),
        dateValue.getDate());
    }
  }
  return null;
}

export function dateTimeConvertLocal(utcValue: string | number | Date): Date {
  if (utcValue) {
    const dateValue = new Date(utcValue);
    const dateNumber = dateValue.getTime();
    const timeZoneOffset = dateValue.getTimezoneOffset();
    if (!isNaN(dateNumber) &&
      !isNaN(timeZoneOffset) &&
      dateNumber !== GlobalConstants.minEpoch) {
      return new Date(
        dateNumber -
        (timeZoneOffset * 60 * 1000));
    }
  }
  return null;
}
