import { Injectable, isDevMode } from '@angular/core';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';

import { Observable, of } from 'rxjs';

import { NgResponse } from '../shared/ng-response';

import { AppInsightsService } from './app-insights.service';
import { AppMessageService } from './app-message.service';
import { Router } from '@angular/router';
import { MessageCodes } from '../shared/message-codes';
import { EmptyResponse } from '../shared/empty-response';

/** Type of the handleError function returned by HttpErrorHandler.createHandleError */
export type HandleError =
  <TResponse> (operation?: string, result?: NgResponse<TResponse>) => (error: HttpErrorResponse) => Observable<NgResponse<TResponse>>;

/** Type of the handleBlobError function returned by HttpErrorHandler.createHandleBlobError */
export type HandleBlobError =
  (operation?: string) => (error: HttpErrorResponse) => Observable<HttpResponse<Blob>>;

/** Handles HttpClient errors */
@Injectable()
export class HttpErrorHandler {
  //TODO: Need to revisit to handle whiteList URLs not to redirect to error page
  readonly apiWhiteList = [ 'reviewlist' ];

  constructor(private readonly appMessageService: AppMessageService,
    private readonly appInsightsService: AppInsightsService,
    private router: Router) { }

  /** Create curried handleError function that already knows the service name */
  createErrorHandler = (service = '') => <TResponse>
    (operation = 'operation', response?: NgResponse<TResponse>) => this.handleError<TResponse>(service, operation, response);

  createBlobErrorHandler = (service = '') =>
    (operation = 'operation', response?: HttpResponse<Blob>) => this.handleBlobError(service, operation, response);

  /**
   * Returns a function that handles Http operation failures.
   * This error handler lets the app continue to run as if no error occurred.
   * @param service = name of the service that attempted the operation
   * @param operation - name of the operation that failed
   * @param response - optional value to return as the observable result
   */
  handleError<TResponse>(service = '', operation = 'operation', response?: NgResponse<TResponse>): (error: HttpErrorResponse) => Observable<NgResponse<TResponse>> {
    return (error: HttpErrorResponse): Observable<NgResponse<TResponse>> => {
      // Log to remote logging infrastructure
      this.appInsightsService.logEvent('Error', { service: service, operation: operation, ...error });

      if (error?.error?.error?.messageCode === MessageCodes.AccessDenied) {
        const urlLastSegment = error.url.substring(error.url.lastIndexOf('/') + 1);
        if (!this.apiWhiteList.includes(urlLastSegment))
          this.router.navigate(['/security/error']);
      } else if (
        /* This is a browser error - usually due to a script error            */ error.error instanceof ErrorEvent ||
        /* This is a JSON parsing error - usually due to a malformed response */ typeof error.error === 'string' ||
        /* This is a JSON parsing error - usually due to a malformed response */ error.error instanceof String)
        this.handleUnknownError(error);
      else if (!response)
        if (error.error && error.error.hasOwnProperty('status') && error.error.hasOwnProperty('error'))
          // This is our JSON response - so let's return that to the caller
          response = error.error as NgResponse<TResponse>;
        else
          // We have no idea what this payload is
          this.handleUnknownError(error);

      // Let the app keep running by returning a safe result.
      return of(response);
    };
  }

  /**
   * Returns a function that handles Http operation failures.
   * This error handler lets the app continue to run as if no error occurred.
   * @param service = name of the service that attempted the operation
   * @param operation - name of the operation that failed
   * @param response - optional value to return as the observable response
   */
  handleBlobError(service = '', operation = 'operation', response: HttpResponse<Blob>): (error: HttpErrorResponse) => Observable<HttpResponse<Blob>> {
    return (error: HttpErrorResponse): Observable<HttpResponse<Blob>> => {
      // Log to remote logging infrastructure
      this.appInsightsService.logEvent('Error', { service: service, operation: operation, ...error });

      if (
        // https://github.com/angular/angular/issues/19888
        // When request of type Blob, the error is also in Blob instead of object of the json data
        error.error instanceof Blob && error.error.type === "application/json") {
        return new Observable<HttpResponse<Blob>>(observer => {
          const reader = new FileReader();
          reader.onload = (event: Event) => {
            try {
              let responseObject = JSON.parse((<any> event.target).result) as NgResponse<EmptyResponse>;

              if (responseObject?.error?.messageCode === MessageCodes.AccessDenied) {
                const urlLastSegment = error.url.substring(error.url.lastIndexOf('/') + 1);
                if (!this.apiWhiteList.includes(urlLastSegment)) {
                  this.router.navigate([ '/security/error' ]);
                  return;
                }
              }

              observer.error(new HttpErrorResponse({
                error: responseObject,
                headers: error.headers,
                status: error.status,
                statusText: error.statusText,
                url: error.url
              }));
            } catch (exception) {
              // Log to remote logging infrastructure
              this.appInsightsService.logException(exception);

              // We have no idea what this payload is
              this.handleUnknownError(error);
            }
          };
          reader.onerror = (event: Event) => {
            // We have no idea what this payload is
            this.handleUnknownError(error);
          };
          reader.readAsText(error.error);
        });
      }
      else if (!response)
        // We have no idea what this payload is
        this.handleUnknownError(error);

      // Let the app keep running by returning a safe result.
      return of(response);
    }
  }

  private handleUnknownError(error: HttpErrorResponse): void {
    if (isDevMode())
      // Log to console
      console.error(error);

    this.appMessageService.add({ severity: 'error', summary: 'Unknown Error', detail: 'An unknown error has occurred. Please contact the Help Desk.' });

    if (error.status === 401 || error.url.includes('login/?ReturnUrl'))
      this.router.navigate(['/security/login', { message: 'No active session' }]);

    if (error.status === 400 || error.url.includes('/api/Submissions/GetOne'))
      this.router.navigate(['/security/login', { message: 'Permission denied' }]);
  }
}
