import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaderResponse,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Router } from '@angular/router';
import { SessionTimerService } from './session-timer.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class TimeoutInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private readonly sessionTimerService: SessionTimerService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.sessionTimerService.resetTimer();
    return next.handle(req)
      .pipe(map(resp => {
        if (resp instanceof HttpHeaderResponse) {
          if (resp.status === 401)
            this.router.navigate([ '/security/login', { message: 'No active session' } ]);
        } else if (resp instanceof HttpErrorResponse) {
          if (resp.status === 401)
            this.router.navigate([ '/security/login', { message: 'No active session' } ]);
        }
        return resp;
      }));
  }
}
