export enum IFCSeverities {
  Warning = 'Warning',
  Fatal = 'Fatal',
  NoSeverity = 'NoSeverity'
}

export class IFCSeverityNames {
  [severity: string]: string;

  constructor() {
    this[IFCSeverities.Warning] = 'Warning';
    this[IFCSeverities.Fatal] = 'Fatal';
    this[IFCSeverities.NoSeverity] = 'No Severity';
  }
}

export class IFCSeverityCodes {
  [severity: string]: string;

  constructor() {
    this[IFCSeverities.Warning] = 'W';
    this[IFCSeverities.Fatal] = 'F';
    this[IFCSeverities.NoSeverity] = 'NS';
  }
}
