import { Params } from "@angular/router";
import { CharterTypes } from "../charter-types";
import { ColumnCodes } from "../column-codes";
import { DatasetTypes } from "../dataset-types";
import { EntityTypes } from "../entity-types";
import { HorizontalAlignments, TypedValue } from "../form.model";
import { ReportingPeriods } from "../reporting-periods";
import { SubmissionItem } from "../submission.model";
import { UserGLDataRecord } from "../user-data.model";
import { TRCSeverity } from "./trc-severity";

export class ComputeTRCGroupsNgRequest {
  versionInfo: Params;
  reportingPeriod: ReportingPeriods;
  datasetType: DatasetTypes;
  fullFiscalYear: string;

  constructor(init?: Partial<ComputeTRCGroupsNgRequest>) {
    Object.assign(this, init);
  }
}

export class ComputeTRCsNgRequest {
  versionInfo: Params;
  groupIds: string[];
  columnCode: ColumnCodes;
  reportingPeriod: ReportingPeriods;
  datasetType: DatasetTypes;
  submissionNumber: string;
  cdsCode: string;
  fullFiscalYear: string;
  selectedColumnCodeFiscalYear: string;

  constructor(
    versionInfo: Params,
    groupIds: string[],
    columnCode: ColumnCodes,
    reportingPeriod: ReportingPeriods,
    datasetType: DatasetTypes,
    submissionNumber: string,
    cdsCode: string,
    fullFiscalYear: string,
    selectedColumnCodeFiscalYear: string) {

    this.versionInfo = versionInfo;
    this.groupIds = groupIds;
    this.columnCode = columnCode;
    this.reportingPeriod = reportingPeriod;
    this.datasetType = datasetType;
    this.submissionNumber = submissionNumber;
    this.cdsCode = cdsCode;
    this.fullFiscalYear = fullFiscalYear;
    this.selectedColumnCodeFiscalYear = selectedColumnCodeFiscalYear;
  }
}

export class SACSStringValidationNgRequest {
  versionInfo: Params;
  groupIds: string[];
  columnCode: ColumnCodes;
  cdsCode: string;
  submissionNumber: string;
  fullFiscalYear: string;
  reportingPeriod: ReportingPeriods;
  fund: string;
  resource: string;
  projectYear: string;
  goal: string;
  function: string;
  object: string;
  amount: number;
  entityType: EntityTypes;
  charterType: CharterTypes;
  previousRecord: UserGLDataRecord;

  constructor(init?: Partial<SACSStringValidationNgRequest>) {
    Object.assign(this, init);
  }
}

export class SACSStringError {
  severityLevel: string;
  trcId: string;
  text: string;
}

export class TRCGroup {
  public id: string;
  public name: string;
  public datasetType: DatasetTypes;
  public trCs: TRC[];

  constructor(init?: Partial<TRCGroup>) {
    Object.assign(this, init);
  }
}

export class TRC {
  public id: string;
  public name: string;
  public message: string
  public severity: TRCSeverity;
  public status: TRCStatus;
  public columns: Column[];
  public sections: Section[];
  public selected: boolean;
  public hasExplanations: boolean;

  constructor(init?: Partial<TRC>) {
    Object.assign(this, init);
  }
}

export class Column {
  public id: number;
  public headers: string[];
  public horizontalAlignment: HorizontalAlignments;
}

export class Section {
  public records: Row[];
  public footers: Footer[];
}

export class Row {
  public cells: Cell[];
  public explanation: string;
}

export class Cell {
  public value: TypedValue;
}

export class Footer {
  public id: number;
  public values: string[];
}

export enum TRCStatus {
  Passed = "Passed",
  Exception = "Exception"
}

export class TRCReportHeader {
  reportDate: string;
  submission: SubmissionItem;
  columnCode: string;

  constructor(init?: Partial<TRCReportHeader>) {
    Object.assign(this, init);
  }
}

export enum TRCExplanationModes {
  SingleExplanation = 'SingleExplanation',
  MultipleExplanations = 'MultipleExplanations'
}

export class TRCExplanationModeNames {
  [trcExplanationMode: string]: string;

  constructor() {
    this[TRCExplanationModes.SingleExplanation] = 'Single Explanation';
    this[TRCExplanationModes.MultipleExplanations] = 'Multiple Explanations';
  }
}
