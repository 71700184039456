import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { HttpClientService } from './http-client.service';
import { HttpErrorHandler } from './http-error-handler';

import { NgRequest } from '../shared/ng-request';
import { NgResponse } from '../shared/ng-response';
import { FilterSortPageRequest, FilterValuesRequest, FilterValuesResponse } from '../shared/grid.model';
import { UserResponse, UsersRequest, UsersResponse, UserViewsResponse, UpdateUserRequest, UpdateUserResponse, DeleteUsersByEmailsRequest, DeleteUsersByEmailsResponse, UsersByRolesRequest} from '../shared/user.model';

@Injectable()
export class UserService extends HttpClientService {
  constructor(readonly httpClient: HttpClient,
    readonly httpErrorHandler: HttpErrorHandler) {
    super('UserService', httpClient, httpErrorHandler);
  }

  getLoggedInUser(): Observable<NgResponse<UserResponse>> {
    return this.get<UserResponse>('getLoggedInUser', `api/Security/LoggedInUser`);
  }

  getUser(email: string): Observable<NgResponse<UserResponse>> {
    return this.get<UserResponse>('getUser', `api/Security/UserWithEntitiesRoles/${email}`);
  }
  getUserById(id: string): Observable<NgResponse<UserResponse>> {
    return this.get<UserResponse>('getUser', `api/Security/GetUserById/${id}`);
  }
  fetchAllUsers(usersRequest: NgRequest<FilterSortPageRequest<UsersRequest>>): Observable<NgResponse<UserViewsResponse>> {
    return this.postBody<FilterSortPageRequest<UsersRequest>, UserViewsResponse>('fetchAllUsers', 'api/Security/AllUsersWithEntitiesRoles', usersRequest);
  }

  fetchFilterValues(usersRequest: NgRequest<FilterValuesRequest<UsersRequest>>): Observable<NgResponse<FilterValuesResponse>> {
    return this.postBody<FilterValuesRequest<UsersRequest>, FilterValuesResponse>('fetchFilterValues', 'api/Security/ViewUsersFilterValues', usersRequest);
  }

  getUsersByAssignedEntity(): Observable<NgResponse<UsersResponse>> {
    return this.get<UsersResponse>('getUsersByAssignedEntity', 'api/Security/AllUsersWithAssignedEntities');
  }

  getAssigntoUsersForQueueEntity(queueBaseElementId: string): Observable<NgResponse<UsersResponse>> {
    return this.get<UsersResponse>(`get${queueBaseElementId}AssignedToUsers`, `api/Security/${queueBaseElementId}/AssignToUsers`);
  }

  updateUser(updateUserRequest: NgRequest<UpdateUserRequest>): Observable<NgResponse<UpdateUserResponse>> {
    return this.putBody<UpdateUserRequest, UpdateUserResponse>('updateUser', 'api/Security/UpdateUser', updateUserRequest);
  }

  deleteUsersByEmails(deleteUsersByEmailsRequest: NgRequest<DeleteUsersByEmailsRequest>): Observable<NgResponse<DeleteUsersByEmailsResponse>> {
    return this.postBody<DeleteUsersByEmailsRequest, DeleteUsersByEmailsResponse>('deleteUsersByEmails', 'api/Security/DeleteUsersByEmails', deleteUsersByEmailsRequest);
  }

  getUsersByRoles(usersByRolesRequest: NgRequest<UsersByRolesRequest>): Observable<NgResponse<UsersResponse>> {
    return this.postBody<UsersByRolesRequest, UsersResponse>('getUsersByRoles', 'api/Security/GetUsersByRoles', usersByRolesRequest);
  }

  getUsersByCDSCode(cdsCode: string): Observable<NgResponse<UsersResponse>> {
    return this.get<UsersResponse>('getUsersByCDSCode', `api/Security/GetUsersByCDSCode/${cdsCode}`);
  }
}
