import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { NgRequest } from '../shared/ng-request';
import { NgResponse } from '../shared/ng-response';

import { HttpClientService } from './http-client.service';
import { HttpErrorHandler } from './http-error-handler';
import { FilterSortPageRequest, FilterValuesRequest, FilterValuesResponse } from '../shared/grid.model';
import { EmptyRequest } from '../shared/empty-request';
import { EmptyResponse } from '../shared/empty-response';
import { FormDependencyItemsApiResponse } from "../tools/form-dependencies/form-dependencies.model";

@Injectable()
export class FormDependencyService extends HttpClientService {
  constructor(readonly httpClient: HttpClient,
    readonly httpErrorHandler: HttpErrorHandler) {
    super('FormDependencyService', httpClient, httpErrorHandler);
  }

  fetchFormDependencyList(request: NgRequest<FilterSortPageRequest<EmptyRequest>>): Observable<NgResponse<FormDependencyItemsApiResponse>> {
    return this.postBody<FilterSortPageRequest<EmptyRequest>, FormDependencyItemsApiResponse>('fetchFormDependencies', 'api/FormDependency/Items', request);
  }

  fetchFormDependencyFilterValues(request: NgRequest<FilterValuesRequest<EmptyRequest>>): Observable<NgResponse<FilterValuesResponse>> {
    return this.postBody<FilterValuesRequest<EmptyRequest>, FilterValuesResponse>('fetchElementFilterValues', 'api/FormDependency/FilterValues', request);
  }

  exportFormDependencyData(request: NgRequest<FilterSortPageRequest<EmptyRequest>>): Observable<HttpResponse<Blob>> {
    return this.postBodyWithBlobResponse<FilterSortPageRequest<EmptyRequest>>('exportFormDependencyData', 'api/FormDependency/Export/Partial', request);
  }

  fullExportFormDependencyData(): Observable<HttpResponse<Blob>> {
    return this.getBlobResponse('exportFormDependencyData', 'api/FormDependency/Export/Full');
  }

  fullImportFormDependencyData(file: File): Observable<NgResponse<EmptyResponse>> {
    const formData: FormData = new FormData();
    formData.append('importFile', file, file.name);
    return this.postFile<EmptyResponse>('fullImportFormDependencyData', 'api/FormDependency/Import/Full', formData);
  }

  partialImportFormDependencyData(file: File): Observable<NgResponse<EmptyResponse>> {
    const formData: FormData = new FormData();
    formData.append('importFile', file, file.name);
    return this.postFile<EmptyResponse>('partialImportFormDependencyData', 'api/FormDependency/Import/Partial', formData);
  }
}
