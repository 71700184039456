import { AccountStatuses } from './account-statuses';

export class AccountStatusNames {
  [accountStatus: string]: string;

  constructor() {
    this[AccountStatuses.Active] = 'Active';
    this[AccountStatuses.Inactive] = 'Inactive';
    this[AccountStatuses.Locked] = 'Locked';
  }
}
