import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { NgRequest } from '../shared/ng-request';
import { NgResponse } from '../shared/ng-response';

import { HttpClientService } from './http-client.service';
import { HttpErrorHandler } from './http-error-handler';
import { FilterSortPageRequest, FilterValuesRequest, FilterValuesResponse } from '../shared/grid.model';
import { StateGLItemsApiResponse, StateElementItemsApiResponse, StateGL, StateElement } from '../tools/state-lists/state-lists.model';
import { EmptyRequest } from '../shared/empty-request';
import { EmptyResponse } from '../shared/empty-response';

@Injectable()
export class StateDataService extends HttpClientService {
  constructor(readonly httpClient: HttpClient,
    readonly httpErrorHandler: HttpErrorHandler) {
    super('StateDataService', httpClient, httpErrorHandler);
  }

  fetchStateElementList(request: NgRequest<FilterSortPageRequest<EmptyRequest>>): Observable<NgResponse<StateElementItemsApiResponse>> {
    return this.postBody<FilterSortPageRequest<EmptyRequest>, StateElementItemsApiResponse>('fetchStateElements', 'api/StateData/Element/Items', request);
  }

  fetchStateGLList(request: NgRequest<FilterSortPageRequest<EmptyRequest>>): Observable<NgResponse<StateGLItemsApiResponse>> {
    return this.postBody<FilterSortPageRequest<EmptyRequest>, StateGLItemsApiResponse>('fetchStateGLs', 'api/StateData/GL/Items', request);
  }

  fetchElementFilterValues(request: NgRequest<FilterValuesRequest<EmptyRequest>>): Observable<NgResponse<FilterValuesResponse>> {
    return this.postBody<FilterValuesRequest<EmptyRequest>, FilterValuesResponse>('fetchElementFilterValues', 'api/StateData/Element/FilterValues', request);
  }

  fetchGLFilterValues(request: NgRequest<FilterValuesRequest<EmptyRequest>>): Observable<NgResponse<FilterValuesResponse>> {
    return this.postBody<FilterValuesRequest<EmptyRequest>, FilterValuesResponse>('fetchGLFilterValues', 'api/StateData/GL/FilterValues', request);
  }

  exportStateElementData(request: NgRequest<FilterSortPageRequest<EmptyRequest>>): Observable<HttpResponse<Blob>> {
    return this.postBodyWithBlobResponse<FilterSortPageRequest<EmptyRequest>>('exportStateElementData', 'api/StateData/Element/Export/Partial', request);
  }

  fullExportStateElementData(): Observable<HttpResponse<Blob>> {
    return this.getBlobResponse('exportStateElementData', 'api/StateData/Element/Export/Full');
  }

  fullImportStateElementData(file: File): Observable<NgResponse<EmptyResponse>> {
    const formData: FormData = new FormData();
    formData.append('importFile', file, file.name);
    return this.postFile<EmptyResponse>('fullImportStateElementData', 'api/StateData/Element/Import/Full', formData);
  }

  partialImportStateElementData(file: File): Observable<NgResponse<EmptyResponse>> {
    const formData: FormData = new FormData();
    formData.append('importFile', file, file.name);
    return this.postFile<EmptyResponse>('partialImportStateElementData', 'api/StateData/Element/Import/Partial', formData);
  }

  fullExportStateGLData(): Observable<HttpResponse<Blob>> {
    return this.getBlobResponse('exportStateGLData', 'api/StateData/GL/Export/Full');
  }

  exportStateGLData(request: NgRequest<FilterSortPageRequest<EmptyRequest>>): Observable<HttpResponse<Blob>> {
    return this.postBodyWithBlobResponse<FilterSortPageRequest<EmptyRequest>>('exportStateGLData', 'api/StateData/GL/Export/Partial', request);
  }

  fullImportStateGLData(file: File): Observable<NgResponse<EmptyResponse>> {
    const formData: FormData = new FormData();
    formData.append('importFile', file, file.name);
    return this.postFile<EmptyResponse>('fullImportStateGLData', 'api/StateData/GL/Import/Full', formData);
  }

  partialImportStateGLData(file: File): Observable<NgResponse<EmptyResponse>> {
    const formData: FormData = new FormData();
    formData.append('importFile', file, file.name);
    return this.postFile<EmptyResponse>('partialImportStateGLData', 'api/StateData/GL/Import/Partial', formData);
  }

  addStateGLRecord(request: NgRequest<StateGL>): Observable<NgResponse<EmptyResponse>> {
    return this.postBody<StateGL, EmptyResponse>('addStateGLRecord', 'api/StateData/GL', request);
  }

  updateStateGLRecord(request: NgRequest<StateGL>): Observable<NgResponse<EmptyResponse>> {
    return this.putBody<StateGL, EmptyResponse>('updateStateGLRecord', 'api/StateData/GL', request);
  }

  deleteStateGLRecord(request: NgRequest<StateGL>): Observable<NgResponse<EmptyResponse>> {
    return this.postBody<StateGL, EmptyResponse>('deleteStateGLRecord', 'api/StateData/GL/Delete', request);
  }

  deleteMultipleStateGLRecord(request: NgRequest<StateGL[]>): Observable<NgResponse<EmptyResponse>> {
    return this.postBody<StateGL[], EmptyResponse>('deleteMultipleStateGLRecord', 'api/StateData/GL/DeleteMultiple', request);
  }

  addStateElementRecord(request: NgRequest<StateElement>): Observable<NgResponse<EmptyResponse>> {
    return this.postBody<StateElement, EmptyResponse>('addStateElementRecord', 'api/StateData/Element', request);
  }

  updateStateElementRecord(request: NgRequest<StateElement>): Observable<NgResponse<EmptyResponse>> {
    return this.putBody<StateElement, EmptyResponse>('updateStateElementRecord', 'api/StateData/Element', request);
  }

  deleteStateElementRecord(request: NgRequest<StateElement>): Observable<NgResponse<EmptyResponse>> {
    return this.postBody<StateElement, EmptyResponse>('deleteStateElementRecord', 'api/StateData/Element/Delete', request);
  }

  deleteMultipleStateElementRecord(request: NgRequest<StateElement[]>): Observable<NgResponse<EmptyResponse>> {
    return this.postBody<StateElement[], EmptyResponse>('deleteMultipleStateElementRecord', 'api/StateData/Element/DeleteMultiple', request);
  }
}
