export class GlobalConstants {
  static readonly maxEpoch = +8640000000000000;
  static readonly minEpoch = -8640000000000000;

  static readonly maxDate = new Date(GlobalConstants.maxEpoch);
  static readonly minDate = new Date(GlobalConstants.minEpoch);

  static readonly DASHBOARD_HELP_PATH = 'userguide/navigation/';
  static readonly EDIT_USER_HELP_PATH = 'userguide/users/login/';
  static readonly VIEW_USER_HELP_PATH = 'userguide/users/admin/';

  //Queues
  static readonly DATASET_APPROVAL_QUEUE_HELP_PATH = 'userguide/queues/dataset_approval/';
  static readonly EDIT_DATASET_QUEUE_HELP_PATH = 'userguide/queues/edit_dataset/';
  static readonly OVER_SIGHT_QUEUE_HELP_PATH = 'userguide/queues/oversight/';
  static readonly PROMOTE_TO_CDE_QUEUE_HELP_PATH = 'userguide/queues/promote_to_cde/';
  static readonly LEA_DRAFT_DATASET_APPROVAL_QUEUE_HELP_PATH = 'userguide/queues/draft_dataset_approval/';

  //Tools
  static readonly SINGLE_STRING_VALIDATION_HELP_PATH = 'userguide/tools/single_string/';
  static readonly PROMOTE_ROLE_REQUEST_HELP_PATH = 'userguide/tools/promoter_role/';
  static readonly BLANK_FORMS_HELP_PATH = 'userguide/tools/blank_form/';

  //Submissions
  static readonly VERSION_HISTORY_SUBMISSION_HELP_PATH = 'userguide/submissions/version/';
  static readonly EXPORT_SUBMISSION_HELP_PATH = 'userguide/submissions/export/';
  static readonly LEA_CUSTOM_INFORMATION_SUBMISSION_HELP_PATH = 'userguide/submissions/custom/';
  static readonly CEFB_SUBMISSION_HELP_PATH = 'userguide/submissions/cefb/';
  static readonly TECHNICAL_REVIEW_CHECKS_SUBMISSION_HELP_PATH = 'userguide/submissions/trc/';
  static readonly USER_INPUT_SUBMISSION_HELP_PATH = 'userguide/submissions/userinput/';
  static readonly TABLE_OF_CONTENTS_SUBMISSION_HELP_PATH = 'userguide/submissions/contents/';
  static readonly COVER_SHEETS_SUBMISSION_HELP_PATH = 'userguide/submissions/coverpage/';
  static readonly FORMS_SUBMISSION_HELP_PATH = 'userguide/submissions/forms/';
  static readonly REPORTS_SUBMISSION_HELP_PATH = 'userguide/submissions/reports/';
  static readonly CRITERIA_AND_STANDARDS_BUDGET_SUBMISSION_HELP_PATH = 'userguide/submissions/forms/cs/form01cs_budget/';
  static readonly CRITERIA_AND_STANDARDS_INTERIM_SUBMISSION_HELP_PATH = 'userguide/submissions/forms/cs/form01cs_interim/';

  //Import
  static readonly IMPORT_SINGLE_HELP_PATH = 'userguide/import/single/';
  static readonly IMPORT_MULTIPLE_HELP_PATH = 'userguide/import/multiple/';
  static readonly IMPORT_OVERWRITE_HELP_PATH = 'userguide/submissions/import/'

  private constructor() {}
}
