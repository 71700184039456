import { Injectable } from '@angular/core';
import { Column, Cell, Row, Tab, Form } from '../shared/form.model';
import { DataTypes } from '../shared/data-types';
import { IFCStatuses } from '../shared/ifc-statuses';
import { IFCSeverities } from '../shared/ifc-severities';

@Injectable()
export class FormProcessor {
  calculateHeaderIndices(form: Form) {
    for (let tabIndex = 0; tabIndex != form.tabs.length; ++tabIndex) {
      const tab = form.tabs[tabIndex];
      const length = tab.columns
        .map(column => column.headers.length)
        .reduce((previous: number, current: number) =>
          Math.max(previous, current));
      tab.headerIndices = new Array<number>();
      for (let index = 0; index !== length; ++index) {
        tab.headerIndices.push(index);
      }
    }
  }

  copyValues(sourceForm: Form, targetForm: Form): void {
    targetForm.ifcResults = sourceForm.ifcResults;
    this.copyTabValues(sourceForm.tabs, targetForm.tabs);
  }

  processCells(form: Form): void {
    for (let tabIndex = 0; tabIndex !== form.tabs.length; ++tabIndex) {
      const tab = form.tabs[tabIndex];
      for (let rowIndex = 0; rowIndex !== tab.rows.length; ++rowIndex) {
        const row = tab.rows[rowIndex];
        for (let cellIndex = 0; cellIndex !== row.cells.length; ++cellIndex) {
          const cell = row.cells[cellIndex];
          cell.value.value = this.convertCellvalue(cell);
        }
      }
    }
  }

  private copyTabValues(sourceTabs: Array<Tab>, targetTabs: Array<Tab>): void {
    const tabCount = Math.min(sourceTabs.length, targetTabs.length);
    for (let tabIndex = 0; tabIndex !== tabCount; ++tabIndex) {
      const sourceTab = sourceTabs[tabIndex];
      const targetTab = targetTabs[tabIndex];
      this.copyColumnValues(sourceTab.columns, targetTab.columns);
      this.copyRowValues(sourceTab.rows, targetTab.rows);
    }
  }

  private copyColumnValues(sourceColumns: Array<Column>, targetColumns: Array<Column>): void {
    const columnCount = Math.min(sourceColumns.length, targetColumns.length);
    for (let columnIndex = 0; columnIndex !== columnCount; ++columnIndex) {
      const sourceColumn = sourceColumns[columnIndex];
      const targetColumn = targetColumns[columnIndex];
      targetColumn.frozen = sourceColumn.frozen;
      targetColumn.value = sourceColumn.value;      
      targetColumn.headers = sourceColumn.headers;
    }
  }

  private copyRowValues(sourceRows: Array<Row>, targetRows: Array<Row>): void {
    const rowCount = Math.min(sourceRows.length, targetRows.length);
    for (let rowIndex = 0; rowIndex !== rowCount; ++rowIndex) {
      const sourceRow = sourceRows[rowIndex];
      const targetRow = targetRows[rowIndex];
      targetRow.hidden = sourceRow.hidden;
      targetRow.deletable = sourceRow.deletable;
      targetRow.editable = sourceRow.editable;
      targetRow.frozen = sourceRow.frozen;
      targetRow.indent = sourceRow.indent;
      targetRow.value = sourceRow.value;
      this.copyCellValues(sourceRow.cells, targetRow.cells);
    }
  }

  private copyCellValues(sourceCells: Array<Cell>, targetCells: Array<Cell>): void {
    const cellCount = Math.min(sourceCells.length, targetCells.length);
    for (let cellIndex = 0; cellIndex !== cellCount; ++cellIndex) {
      const sourceCell = sourceCells[cellIndex];
      const targetCell = targetCells[cellIndex];
      const convertedCellValue = this.convertCellvalue(sourceCell);
      targetCell.value.dataType = sourceCell.value.dataType;
      targetCell.value.value = convertedCellValue;
      targetCell.displayValue = sourceCell.displayValue;
      targetCell.message = sourceCell.message;
      targetCell.editorOptions = sourceCell.editorOptions;
      targetCell.elementCode = sourceCell.elementCode;
      targetCell.edit = sourceCell.edit;
    }
  }

  private convertCellvalue(cell: Cell): boolean | Date | number | string {
    if (!cell.value || !cell.value.value)
      return cell.value.value;

    switch (cell.value.dataType) {
      case DataTypes.Number:
        return Number(cell.value.value);

      case DataTypes.DateTime:
        return new Date(cell.value.value.toString());

      default:
        return cell.value.value;
    }
  }

  public copyIFCs(sourceForm: Form, targetForm: Form): void {
    targetForm.ifcResults.splice(0, targetForm.ifcResults.length, ...sourceForm.ifcResults);
    this.updateHasIFCErrors(targetForm);
  }

  public updateHasIFCErrors(form: Form): void {
    form.hasIFCErrors = form.ifcResults.some(r => r.status === IFCStatuses.Fail);
    form.hasNoNSIFCErrors = form.ifcResults.some(r => r.status === IFCStatuses.Fail && r.severity !== IFCSeverities.NoSeverity);
  }
}
