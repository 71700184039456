export enum EntityRelationshipTypes {
  Parent = 'Parent',
  Child = 'Child'
}

export class EntityRelationshipTypeNames {
  [entityRelationshipType: string]: string;

  constructor() {
    this[EntityRelationshipTypes.Parent] = 'Parent';
    this[EntityRelationshipTypes.Child] = 'Child';
  }
}
