export enum MessageCodes {
  Success = -1,
  UnknownError = 0,
  MissingField = 1,
  InvalidCharacters = 2,
  InvalidEntry = 3,
  PasswordsMismatch = 4,
  EmailOrPasswordMismatch = 5,
  PasswordExpired = 6,
  PasswordSaved = 7,
  PasswordCriteriaNotMet = 8,
  InvalidLogInLink = 9,
  PasswordExpiresSoon = 10,
  MaximumLogInAttemptsReached = 11,
  InactiveAccount = 12,
  AccountUpdated = 13,
  UnsavedChanges = 14,
  AccountExists = 15,
  RoleConflict = 16,
  EmailBadSyntax = 17,
  Cancelled = 18,
  AccountDoesNotExist = 19,
  NewAccountSuccessful = 20,
  DeleteAccountSuccessful = 21,
  CannotLogin = 22,
  CannotLogout = 23,
  InvalidFileType = 24,
  NotOfficialFile = 25,
  MaximumDatasetsCreationReached = 26,
  OneSubmissionDatasetAllowed = 27,
  NoRecordsToDisplay = 28,
  LEADoesNotExist = 29,
  ResetPasswordRequestSent = 30,
  SubmissionNotFound = 31,
  AccessDenied = 32,
  ExistingPasswordMismatch = 33,
  InvalidImportFileDataType = 34,
  InvalidFiscalYear = 35,
  SubmissionDatasetExists = 36,
  InvalidProjectYear = 37,
  CDSCodeInvalidCOESBE = 38,
  DataConflict = 39,
  DataInconsistencyTrailerLEAHeaderLEA = 40,
  DataInconsistencyTrailerReportingPeriodHeaderReportingPeriod = 41,
  DataInconsistencyTrailerTypeOfDataHeaderTypeOfData = 42,
  DataInconsistencyTrailerEndOfFile = 43,
  DataInconsistencyTrailerRecordCount = 44,
  DataInconsistencyTrailerLEAHeaderSchool = 45,
  ImportDiscontinued = 46,
  OfficialCountsNotMatch = 47,
  OfficialImportInvalid = 48,
  FundCodeRequired = 49,
  ResourceCodeRequired = 50,
  ProjectYearCodeRequired = 51,
  GoalCodeRequired = 52,
  FunctionCodeRequired = 53,
  ObjectCodeRequired = 54,
  ValueRequired = 55,
  InactiveAccountNewEmail = 56,
  CDSNumberNotExistsOnExpectedToFileList = 57,
  SectionHeaderMismatch = 58,
  DeleteDiscontinues = 59,
  OldPasswordNewPasswordMatch = 60,
  MultipleUploadInconsistencySelectedReportingPeriod = 61,
  DuplicateSACSString = 62,
  FundHasImportedData = 63,
  NoFund9697ForBudgetOrInterim = 64,
  NoObjects9100Thru9699BudgetOrInterim = 65,
  NoFund9697ForBudget = 66,
  InvalidSelectedYearsForDescriptionDetail = 67,
  CharterNumberRequired = 68,
  CodeCombinationExists = 69,
  InvalidSelectedYearsForCodeCombination = 70,
  CCodeNumberRequired = 71,
  LEAIdNumberRequired = 72,
  SchoolIdNumberRequired = 73,
  ValidCodeExists = 74,
  CodeCombinationsExist = 75,
  TimePeriodEndBeforeStart = 76,
  OverlappingNewsAnnouncementExists = 77,
  TRCRerunRequired = 78,
  PrescanNotFound = 79,
  SystemVersionNotMatch = 80,
  VersionNumberRequired = 81,
  TRCsInvalid = 82,
  OfficialValidationFailed = 83,
  CDSCodeNotForCharterSchool = 84,
  MultipleUploadInconsistencySelectedDatasetType = 85,
  SubmissionIsLockedCannotContinue = 86,
  InvalidYear = 87,
  InvalidSystemVersion = 88,
  LastRemainingSystemVersion = 89,
  NoEditPermission = 90,
  CDSCodeInvalid = 91,
  InvalidSectionType = 92,
  NoGLDataForSelectedDataType = 93,
  UploadOverwriteInconsistencyDatasetType = 94,
  OfficialValidationSuccess = 95,
  UploadOverwriteNotMatchCDSCodeReportingPeriodFiscalYear = 96,
  FeatureDisabledPublicUser = 97,
  PrescanFailed = 98,
  DataAlreadyModified = 99,
  IdenticalSystemVersions = 100,
  InvalidAuthorizingAgency = 101,
  InvalidCoversheetCharters = 102,
  InvalidFormGroupId = 103,
  InvalidFormId = 104,
  InvalidFormInstanceId = 105,
  BlobNotFound = 106,
  NoRolesForThisEntity = 107,
  NoRolesForSomeEntities = 108,
  InvalidFileSize = 109,
  InvalidFileTypeAttached = 110,
  CDSCodeInvalidLength = 111,
  DistributionListNotFound = 112,
  DistributionListOrMemberNotFound = 113,
  DuplicateDistributionList = 114,
  DuplicateDistributionListMember = 115,
  COESoftwareContactNotFound = 116,
  DuplicateCOESoftwareContact = 117,
  RunReportsJobCreated = 118,
  RunReportsJobExists = 119,
  DataExportFinished = 120,
  DataImportStarted = 121,
  ExportUserDataJobCreated = 122,
  ExportUserDataJobExists = 123,
  QuerySACSJobCreated = 124,
  RunExportJobCreated = 125,
  RunTRCsJobCreated = 126,
  NotFound = 129,
  InvalidImportFile = 130,
  InvalidImportRequest = 131,
  InvalidOperation = 132,
  ImportExportJobExists = 133,
  CreatingSubmissionsForCDEOrSBEAreNotAllowed = 134,
  InvalidRequest = 135,
  DuplicateRecordExists = 136,
  ArtifactNotFound = 137,
  CommonAdminMemberExists = 138,
  AdminAndMemberCDSCodeCannotBeSame = 139,
  OverlappingRecordExists = 140,
  SelectedEntityHasNotSubmittedDataOrIsNotRequired = 141,
  ObjectDownloadFailed = 142,
  RelatedRecordNotDeleted = 143,
  NewAndLapsedCDSCodeCannotBeSame = 144,
  FeatureNotAvailable = 145,
  MaximumOneActiveParentPerEntity = 146,
  InvalidEntityRelationshipType = 147,
  NoArtifactsReadyToPublish = 148,
  ActiveParentRelationshipRequired = 149,
  MaximumOneActiveSubtypePerEntity = 150,
  RelatedRecordNotFound = 151,
  ProcessingSubmissionArtifacts = 152,
  GeneratingRollupArtifacts = 153,
  PublishingArtifacts = 154
}
