import { Injectable } from '@angular/core';
import { HttpClientService } from './http-client.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { HttpErrorHandler } from './http-error-handler';
import { NgResponse } from '../shared/ng-response';
import { Observable } from 'rxjs';
import { NgRequest } from '../shared/ng-request';
import { FilterSortPageRequest, FilterValuesRequest, FilterValuesResponse } from '../shared/grid.model';
import { BatchJobsApiRequest, BatchJobsApiResponse, BatchLogApiResponse } from '../tools/batch-operations/batch-operations.model'
import { BatchJobApiRequest } from '../shared/batch.models';
import { EmptyResponse } from '../shared/empty-response';

@Injectable({
  providedIn: 'root'
})
export class BatchService extends HttpClientService {
  constructor(
    readonly httpClient: HttpClient,
    readonly httpErrorHandler: HttpErrorHandler) {
    super('BatchService', httpClient, httpErrorHandler);
  }

  createJob(jobRequest: NgRequest<BatchJobApiRequest>): Observable<NgResponse<EmptyResponse>> {
    return this.postBody<BatchJobApiRequest, EmptyResponse>('createJob', 'api/BatchJob', jobRequest);
  }

  cancelJob(id: string): Observable<NgResponse<EmptyResponse>> {
    return this.put<EmptyResponse>('cancelJob', `api/BatchJob/${id}`);
  }

  deleteJob(id: string): Observable<NgResponse<EmptyResponse>> {
    return this.delete<EmptyResponse>('deleteJob', `api/BatchJob/${id}`);
  }

  reRunJob(id: string): Observable<NgResponse<EmptyResponse>> {
    return this.post<EmptyResponse>('reRunJob', `api/BatchJob/${id}`);
  }

  getJobBlob(id: string): Observable<HttpResponse<Blob>> {
    return this.getBlobResponse('getJobBlob', `api/BatchJob/${id}/Blob`);
  }

  getJobLog(id: string): Observable<NgResponse<BatchLogApiResponse>> {
    return this.get<BatchLogApiResponse>('getJobLog', `api/BatchJob/${id}/Log`);
  }

  fetchBatchJobs(request: NgRequest<FilterSortPageRequest<BatchJobsApiRequest>>): Observable<NgResponse<BatchJobsApiResponse>> {
    return this.postBody<FilterSortPageRequest<BatchJobsApiRequest>, BatchJobsApiResponse>('fetchBatchJobs', `api/BatchJobs`, request);
  }

  fetchBatchJobFilterValues(request: NgRequest<FilterValuesRequest<BatchJobsApiRequest>>): Observable<NgResponse<FilterValuesResponse>> {
    return this.postBody<FilterValuesRequest<BatchJobsApiRequest>, FilterValuesResponse>('fetchBatchJobFilterValues', 'api/BatchJobs/FilterValues', request);
  }
}
