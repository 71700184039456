export abstract class FormGroupIds {
  static readonly TableOfContents = 'TableOfContents';
  static readonly Funds = 'Funds';
  static readonly Supplementals = 'Supplementals';
  static readonly CEFB = 'CEFB';
  static readonly CoverSheets = 'CoverSheets';
  static readonly Reports = 'Reports';
  static readonly CriteriaAndStandards = 'CriteriaAndStandards';
  static readonly ALT = 'ALT';
}
