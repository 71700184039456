import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable, forkJoin } from 'rxjs';

import { LoggedInUserService } from './services/loggedInUser.service';
import { ReferenceDataService } from './services/reference-data.service';

@Injectable()
export class AppResolver implements Resolve<Observable<any>> {
  constructor(private loggedInUserService: LoggedInUserService,
    private referenceDataService: ReferenceDataService) {
  }

  resolve(route: ActivatedRouteSnapshot,
          state: RouterStateSnapshot): Observable<Observable<any>> | Promise<Observable<any>> | Observable<any> {
    return forkJoin([
      this.loggedInUserService.init(),
      this.referenceDataService.init(),
      this.referenceDataService.getAppInsightsKey()
    ]);
  }
}
