export enum Roles {
  Any = 'Any',
  Administration = 'Administration',
  Analyst = 'Analyst',
  CertifyOrPromoteToCDE = 'CertifyOrPromoteToCDE',
  Consultant = 'Consultant',
  DatasetApproval = 'DatasetApproval',
  EditDataset = 'EditDataset',
  HelpManagement = 'HelpManagement',
  LeadWorkflowConsultant = 'LeadWorkflowConsultant',
  Oversight = 'Oversight',
  Publish = 'Publish',
  SystemAdministration = 'SystemAdministration',
  UserManagement = 'UserManagement',
  ValidationTableMaintenance = 'ValidationTableMaintenance',
  WorkflowManagement = 'WorkflowManagement',
  EditDraftDataset = 'EditDraftDataset',
  SELPAAU ='SELPAAU',
  SELPAMember = 'SELPAMember'
}
