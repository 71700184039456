import { Enum } from './enum';
import { OversightReviewStatuses } from './oversight-review-statuses';

export class OversightReviewStatusNames {
  [oversightReviewStatus: number]: string;

  constructor() {
    const oversightReviewStatusNameValues = Enum.getNamesAndValues(OversightReviewStatuses);
    oversightReviewStatusNameValues.forEach(oversightReviewStatus => {
      this[oversightReviewStatus.value] = oversightReviewStatus.name;
    });
  }
}
