export enum TRCSeverity {
    Informational = "Informational",
    Warning = "Warning",
    WarningCalculation = "WarningCalculation",
    Fatal = "Fatal"
}

export class TRCSeverityNames {
    [trcSeverity: string]: string;

    constructor() {
        this[TRCSeverity.Informational] = 'Informational';
        this[TRCSeverity.Fatal] = 'Fatal';
        this[TRCSeverity.Warning] = 'Warning';
        this[TRCSeverity.WarningCalculation] = 'Warning Calculation';
    }
}

export class TRCSeverityCodes {
  [trcSeverity: string]: string;

  constructor() {
    this[TRCSeverity.Informational] = 'O';
    this[TRCSeverity.Fatal] = 'F';
    this[TRCSeverity.Warning] = 'W';
    this[TRCSeverity.WarningCalculation] = 'WC';
  }
}
