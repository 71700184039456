import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataNgResponse } from '../shared/data-ng-response';
import { LockFormRequest, SubmissionLockData } from '../shared/lock.model';
import { NgRequest } from '../shared/ng-request';
import { NgResponse } from '../shared/ng-response';
import { HttpClientService } from './http-client.service';
import { HttpErrorHandler } from './http-error-handler';
import { RunModes } from '../shared/runs-modes';

@Injectable()
export class LocksService extends HttpClientService {
  constructor(readonly httpClient: HttpClient,
    readonly httpErrorHandler: HttpErrorHandler) {
    super('LocksService', httpClient, httpErrorHandler);
  }

  lockSubmission(submissionNumber: string): Promise<NgResponse<SubmissionLockData>> {
    return new Promise(resolve => this.put<SubmissionLockData>('lockSubmission', `api/Locks/Submission/${submissionNumber}`).subscribe(ngResponse => resolve(ngResponse)));
  }

  unlockSubmission(submissionNumber: string, unlockOverride: boolean, runMode?: RunModes, testRunId?: string): Promise<NgResponse<SubmissionLockData>> {
    return !runMode || !testRunId
      ? new Promise(resolve => this.delete<SubmissionLockData>('unlockSubmission', `api/Locks/Submission/${submissionNumber}/${unlockOverride}`).subscribe(ngResponse => resolve(ngResponse)))
      : new Promise(resolve => this.delete<SubmissionLockData>('unlockSubmission', `api/Locks/Submission/${submissionNumber}/${unlockOverride}/${runMode}/${testRunId}`).subscribe(ngResponse => resolve(ngResponse)));
  }

  fetch(submissionNumber: string): Promise<NgResponse<SubmissionLockData>> {
    return new Promise(resolve => this.get<SubmissionLockData>('fetch', `api/Locks/${submissionNumber}`).subscribe(ngResponse => resolve(ngResponse)));
  }

  lockForm(request: NgRequest<LockFormRequest>): Promise<NgResponse<SubmissionLockData>> {
    return new Promise(resolve => this.putBody<LockFormRequest, SubmissionLockData>('lockForm', `api/Locks/Form/`, request).subscribe(ngResponse => resolve(ngResponse)));
  }

  unlockForm(submissionNumber: string, instanceId: string, unlockOverride: boolean, runMode?: RunModes, testRunId?: string): Promise<NgResponse<SubmissionLockData>> {
    return !runMode || !testRunId
      ? new Promise(resolve => this.delete<SubmissionLockData>('unlockForm', `api/Locks/Form/${submissionNumber}/${instanceId}/${unlockOverride}`).subscribe(ngResponse => resolve(ngResponse)))
      : new Promise(resolve => this.delete<SubmissionLockData>('unlockForm', `api/Locks/Form/${submissionNumber}/${instanceId}/${unlockOverride}/${runMode}/${testRunId}`).subscribe(ngResponse => resolve(ngResponse)));
  }

  unlockAll(submissionNumber: string, runMode?: RunModes, testRunId?: string): Promise<NgResponse<SubmissionLockData>> {
    return !runMode || !testRunId
      ? new Promise(resolve => this.delete<SubmissionLockData>('unlockSubmission', `api/Locks/All/${submissionNumber}`).subscribe(ngResponse => resolve(ngResponse)))
      : new Promise(resolve => this.delete<SubmissionLockData>('unlockSubmission', `api/Locks/All/${submissionNumber}/${runMode}/${testRunId}`).subscribe(ngResponse => resolve(ngResponse)));
  }

  isSubmissionLockedByCurrentUser(submissionNumber: string): Promise<NgResponse<DataNgResponse<boolean>>> {
    return new Promise(resolve => this.get<DataNgResponse<boolean>>('isSubmissionLockedByCurrentUser', `api/Locks/Submission/IsLockedByCurrentUser/${submissionNumber}`).subscribe(ngResponse => resolve(ngResponse)));
  }

  isFormLockedByCurrentUser(submissionNumber: string, formInstanceId: string): Promise<NgResponse<DataNgResponse<boolean>>> {
    return new Promise(resolve => this.get<DataNgResponse<boolean>>('isFormLockedByCurrentUser', `api/Locks/Form/IsLockedByCurrentUser/${submissionNumber}/${formInstanceId}`).subscribe(ngResponse => resolve(ngResponse)));
  }
}
