import { ReportingPeriods } from './reporting-periods';

export class ReportingPeriodNames {
  [reportingPeriod: string]: string;

  constructor() {
    this[ReportingPeriods.Any] = 'Any';
    this[ReportingPeriods.Unknown] = 'Unknown';
    this[ReportingPeriods.BS1] = 'Budget, July 1';
    this[ReportingPeriods.A] = 'Unaudited Actuals';
    this[ReportingPeriods.I1] = 'First Interim';
    this[ReportingPeriods.I2] = 'Second Interim';
    this[ReportingPeriods.I3] = 'End of Year Projection';
  }
}

export class ReportingPeriodNameRanks {
  [rank: number]: string;

  constructor() {
    this[1] = 'Budget, July 1';
    this[2] = 'End of Year Projection';
    this[3] = 'First Interim';
    this[4] = 'Second Interim';
    this[5] = 'Unaudited Actuals';    
  }
}
