import { UserGLDeterminations } from './user-gl-determinations';

export class UserGLDeterminationTexts {
  [userGLDetermination: string]: string;

  constructor() {
    this[UserGLDeterminations.CharterSubmitSACSWithCSMReportFormatSACSReportLevelCOEOrDistrict] = 'Present in UserGL, not identified as filing SACS, school level';
    this[UserGLDeterminations.CharterSubmitALTWithCSMReportFormatSACSReportLevelCOEOrDistrict] = 'Present in ALT data, not identified as filing ALT form, school level';
    this[UserGLDeterminations.CharterSubmitALTWithCSMReportFormatSACSReportLevelCharterOrSBE] = 'Not present in UserGL';
    this[UserGLDeterminations.CharterSubmitSACSWithCSMReportFormatALTReportLevelCharterOrSBE] = 'Not present in ALT data';
  }
}
