export class NgRequest<TRequest> {
  private constructor(
    readonly request: TRequest,
    readonly runMode: string,
    readonly testRunId: string,
    readonly timeZoneId: string) { }

  static create<TRequest>(
    request: TRequest,
    runMode: string,
    testRunId: string,
    timeZoneId: string): NgRequest<TRequest> {
    return new NgRequest<TRequest>(request, runMode, testRunId, timeZoneId);
  }
}
