import { Component } from '@angular/core';

import { AppContext } from './app.context';
import { BaseComponent } from './base.component';

import { AppInsightsService } from './services/app-insights.service';
import { ReferenceDataService } from './services/reference-data.service';
import { ActivatedRoute } from '@angular/router';
import { TitleService } from './services/title.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    AppInsightsService
  ]
})
export class AppComponent extends BaseComponent {
  switch = null;

  constructor(readonly appContext: AppContext,
    readonly appInsightsService: AppInsightsService,
    readonly activatedRoute: ActivatedRoute,
    readonly referenceDataService: ReferenceDataService,
    readonly titleService: TitleService) {
    super(appContext, appInsightsService);

    titleService.init();

    this.activatedRoute.queryParamMap.subscribe(queryParams => {
      this.switch = queryParams.get('switch');
    });
  }
}
