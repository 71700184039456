import { FiscalYearStatus } from "./fiscal-year-status";

export class FiscalYearStatusNames {
  [fiscalYearStatusCode: string]: string;

  constructor() {
    this[FiscalYearStatus.Unknown] = "Unknown";
    this[FiscalYearStatus.Active] = "Active";
    this[FiscalYearStatus.Inactive] = "Inactive";
  }
}
