import { Enum } from './enum';
import { CharterReviewStatuses } from './charter-review-statuses';

export class CharterReviewStatusNames {
  [charterReviewStatus: string]: string;

  constructor() {
    const charterReviewStatusNameValues = Enum.getNamesAndValues(CharterReviewStatuses);
    charterReviewStatusNameValues.forEach(charterReviewStatus => {
      this[charterReviewStatus.value] = charterReviewStatus.name;
    });
  }
}
