import { TRCOutcome } from './trc-outcome';

export class TRCOutcomeNames {
  [trcCode: string]: string;

  constructor() {
    this[TRCOutcome.Any] = 'Any';
    this[TRCOutcome.Unknown] = 'Unknown';
    this[TRCOutcome.Pass] = 'No Exceptions';
    this[TRCOutcome.WExpl] = 'Warning Exceptions';
    this[TRCOutcome.WInfo] = 'Informational Only';
    this[TRCOutcome.FExpl] = 'Fatal Exceptions';
  }
}
