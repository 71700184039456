import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { CookieService } from 'ngx-cookie-service';

import { AppContext } from '../app.context';

import { StatusTypes } from '../shared/status-types';

import { UserService } from '../services/user.service';

@Injectable()
export class LoggedInUserService {
  private readonly authCookieName = '.AspNetCore.Cookies';

  constructor(private readonly appContext: AppContext,
              private readonly userService: UserService,
              private readonly cookieService: CookieService) { }

  init(): Observable<any> {
    return new Observable(observer => {
      if (this.isLoggedIn) {
        this.userService
          .getLoggedInUser()
          .subscribe(ngResponse => {
            if (!ngResponse || ngResponse.status === StatusTypes.Failure) {
              this.appContext.loggedInUser = null;
              this.cookieService.delete(this.authCookieName);
            } else
              this.appContext.loggedInUser = ngResponse.response.user;

            observer.next(null);
            observer.complete();
          });
      } else {
        observer.next(null);
        observer.complete();
      }
    });
  }

  get isLoggedIn(): boolean {
    const cookie = this.cookieService.get(this.authCookieName);
    return typeof cookie === 'string' && cookie !== '';
  }
}
