import { DatasetTypes } from "./dataset-types";

export class DatasetTypeNames {
  [dataSetType: string]: string;

  constructor() {
    this[DatasetTypes.SACS] = 'SACS';
    this[DatasetTypes.ALT] = 'Charter Alternative';
  }
}
