import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { HttpClientService } from './http-client.service';
import { HttpErrorHandler } from './http-error-handler';

import { NgRequest } from '../shared/ng-request';
import { NgResponse } from '../shared/ng-response';
import { CreateHelpDeskTickeResponse, HelpDeskModel } from '../navigation/top/usermenu/help-desk.model';

@Injectable()
export class HelpDeskService extends HttpClientService {
  constructor(readonly httpClient: HttpClient,
    readonly httpErrorHandler: HttpErrorHandler) {
    super('HelpDeskService', httpClient, httpErrorHandler);
  }

  createHelpDeskTicket(createTicketNgRequest: NgRequest<HelpDeskModel>): Observable<NgResponse<CreateHelpDeskTickeResponse>> {
    return this.postBody<HelpDeskModel, CreateHelpDeskTickeResponse>('helpDeskTicket', 'api/Communication/CreateHelpDeskTicket', createTicketNgRequest);
  }
}
