import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { DefaultColumnCodesMap } from './shared/default-columncodes-map';
import { EntityTypeMap } from './shared/entity-type-map';
import { EntityType } from './shared/entity-type.model';
import { NgMessage } from './shared/ng-message';
import { NgMessageMap } from './shared/ng-message-map';
import { RoleMap } from './shared/role-map';
import { Role } from './shared/role.model';
import { StateList } from './shared/state-list.model';
import { StateListMap } from './shared/states-list-map';
import { User } from './shared/user.model';
import { NgRequest } from './shared/ng-request';
import { SystemVersion } from './shared/system-version.model';
import { FeatureDisable, UploadAttachmentDetails } from './shared/security.model';
import { FiscalYearFormat } from './shared/fiscal-year.model';

@Injectable()
export class AppContext {
  roles: RoleMap;
  messages: NgMessageMap;
  entityTypes: EntityTypeMap;
  statesList: StateListMap;
  stateList: Array<StateList>;
  defaultColumnCodes: DefaultColumnCodesMap;
  systemVersion: SystemVersion;
  buildVersion: string;
  private loggedInUserObject: User;
  public appInsights: ApplicationInsights;
  featureDisable: FeatureDisable;
  fiscalYears: Array<FiscalYearFormat>;
  uploadAttachmentDetails: UploadAttachmentDetails;

  readonly timeZoneId: string;

  private readonly loggedInUserEmailStorageKey = 'SACS:AppContext:loggedInUserEmail';
  private readonly postLoginReturnUrlStorageKey = 'SACS:AppContext:postLoginReturnUrl';
  private readonly runModeKey = 'SACS:AppContext:runMode';
  private readonly testRunIdKey = 'SACS:AppContext:testRunId';
  private readonly maxDraftSubmissions = 5;
  private readonly sessionExpiryAlertAt = 5 * 60;
  private readonly sessionExpiry = 30 * 60;

  setReferenceData(
    roles: Array<Role>,
    messages: Array<NgMessage>,
    entityTypes: Array<EntityType>,
    stateList: Array<StateList>,
    systemVersion: SystemVersion,
    buildVersion: string,
    featureDisable: FeatureDisable,
    fiscalYears: Array<FiscalYearFormat>,
    uploadAttachmentDetails: UploadAttachmentDetails): void {
    this.featureDisable = featureDisable;
    this.systemVersion = systemVersion;
    this.buildVersion = buildVersion;
    this.fiscalYears = fiscalYears;
    this.uploadAttachmentDetails = uploadAttachmentDetails;

    this.roles = new RoleMap();
    for (const role of roles)
      this.roles[role.name] = role;

    this.messages = new NgMessageMap();
    for (const message of messages)
      this.messages[message.code] = message;

    this.entityTypes = new EntityTypeMap();
    for (const entityType of entityTypes)
      this.entityTypes[entityType.name] = entityType;

    this.stateList = stateList;
    this.statesList = new StateListMap();
    for (const state of stateList)
      this.statesList[state.name] = state;

    this.defaultColumnCodes = new DefaultColumnCodesMap();
  }

  get loggedInUser(): User {
    return this.loggedInUserObject;
  }

  set loggedInUser(value: User) {
    this.loggedInUserObject = value;
    if (value)
      sessionStorage.setItem(this.loggedInUserEmailStorageKey, value.email);
    else
      sessionStorage.removeItem(this.loggedInUserEmailStorageKey);
  }

  set runMode(value: string) {
    if (value)
      sessionStorage.setItem(this.runModeKey, value);
    else
      sessionStorage.removeItem(this.runModeKey);
  }

  get runMode(): string {
    return sessionStorage.getItem(this.runModeKey);
  }

  set testRunId(value: string) {
    if (value)
      sessionStorage.setItem(this.testRunIdKey, value);
    else
      sessionStorage.removeItem(this.testRunIdKey);
  }

  get testRunId(): string {
    return sessionStorage.getItem(this.testRunIdKey);
  }

  get pageTitle(): string {
    return this.title.getTitle();
  }

  set pageTitle(newTitle: string) {
    this.title.setTitle(newTitle);
  }

  get loggedInUserEmail(): string {
    return sessionStorage.getItem(this.loggedInUserEmailStorageKey);
  }

  get postLoginReturnUrl(): string {
    return sessionStorage.getItem(this.postLoginReturnUrlStorageKey);
  }

  set postLoginReturnUrl(value: string) {
    sessionStorage.setItem(this.postLoginReturnUrlStorageKey, value);
  }

  get maximumDraftSubmissions(): number {
    return this.maxDraftSubmissions;
  }

  get sessionTimeoutAlertAt(): number {
    return this.sessionExpiryAlertAt;
  }

  get sessionTimeout(): number {
    return this.sessionExpiry;
  }

  createRequest<TRequest>(request: TRequest): NgRequest<TRequest> {
    return NgRequest.create(request, this.runMode, this.testRunId, this.timeZoneId);
  }

  constructor(private readonly title: Title) {
    try {
      this.timeZoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    catch (e) {
      this.timeZoneId = 'America/Los_Angeles';
    }
  }

  get defaultCAFiscalYear(): number {
    const defaultFiscalYearIndex = Math.max(0, this.fiscalYears.findIndex(
      fiscalYear => fiscalYear.fullFiscalYear === this.loggedInUser.defaultFiscalYear));
    return this.fiscalYears[defaultFiscalYearIndex].caFiscalYear;
  }
}
