import { Injectable } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class TitleService {
  constructor(private readonly router: Router,
              private readonly titleService: Title) { }

  init() {
    this.router.events.subscribe((data) => {
      if (data instanceof RoutesRecognized) {
        let pageTitle = 'SACS Web';
        let route = data.state.root;
        while (route.firstChild) {
          route = route.firstChild;
          if (route.data.title) {
            pageTitle = route.data.title;
            break;
          }
        }

        this.titleService.setTitle(pageTitle);
      }
    });
  }
}
