import { Component } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { AppContext } from './app.context';
import { AppInsightsService } from './services/app-insights.service';
import { trimChars } from './shared/string-operations';

@Component({
  template: '',
  providers: [
    AppInsightsService
  ]
})
export class BaseComponent {
  constructor(
    readonly appContext: AppContext,
    readonly appInsightsService: AppInsightsService) {
    this.appInsightsService.logPageView();
  }

  getFileName(blobResponse: HttpResponse<Blob>): string {
    let fileName: string = undefined;

    const contentDisposition = blobResponse.headers.get('content-disposition');

    if (contentDisposition && contentDisposition.length > 0) {
      const fileNameValue = contentDisposition.split(';')
        .map(v => v.trim()).filter(v => v.startsWith('filename='));
      if (fileNameValue) {
        fileName = fileNameValue.shift().split('=').pop();
        fileName = trimChars(fileName, '"');
      }
    }

    return fileName;
  }

  downloadFile(blobResponse: HttpResponse<Blob>, fileName?: string): string {
    fileName = fileName || this.getFileName(blobResponse);

    const file = new File([blobResponse.body], fileName, { type: blobResponse.body.type });
    const url = window.URL.createObjectURL(file);

    const anchorTag = document.createElement('a');
    document.body.appendChild(anchorTag);
    anchorTag.style.cssText = 'display: none';
    anchorTag.href = url;
    anchorTag.download = fileName;
    anchorTag.click();

    window.URL.revokeObjectURL(url);

    return url;
  }

  previewFile(blobResponse: HttpResponse<Blob>, fileName?: string): string {
    fileName = fileName || this.getFileName(blobResponse);

    const file = new File([blobResponse.body], fileName, { type: blobResponse.body.type });
    const url = URL.createObjectURL(file);

    const pdfWindow = window.open(url, '_blank');
    const pdfWindowIntervalId = setInterval(() => {
      if (pdfWindow && pdfWindow.closed) {
        URL.revokeObjectURL(url);
        clearInterval(pdfWindowIntervalId);
      }
    }, 1000);

    return url;
  }
}
