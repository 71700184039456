import { Component, OnDestroy } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { BatchDialogData, BatchDialogResults } from '../batch.models';

@Component({
  selector: 'app-batch-dialog',
  templateUrl: './batch-dialog.component.html',
  styleUrls: ['./batch-dialog.component.scss']
})
export class BatchDialogComponent implements OnDestroy {
  data: BatchDialogData;
  results: BatchDialogResults;
  exportTypeSelection: string;

  readonly fileNamePattern = '[^\\.\\/:*?"<>\\|]{1}[^\\/:*?"<>\\|]{0,199}';

  constructor(
    readonly dialogRef: DynamicDialogRef,
    readonly config: DynamicDialogConfig) {
    this.data = config.data as BatchDialogData;
    this.results = new BatchDialogResults();
    this.exportTypeSelection = 'Other';
  }

  ngOnDestroy(): void {
    this.dialogRef.destroy();
  }

  createBatch(): void {
    this.dialogRef.close(new BatchDialogResults({
      name: this.results.name,
      isOfficial: this.results.isOfficial
    }));
  }

  cancel(): void {
    this.dialogRef.close(null);
  }

  onExportTypeSelection(selectedExportType: boolean) {
    this.results.isOfficial = selectedExportType;
  }
}
