import { Enum } from './enum';
import { LEASelfCertStatuses } from './lea-self-cert-statuses';

export class LEASelfCertStatusNames {
  [leaSelfCertStatus: number]: string;

  constructor() {
    const leaSelfCertStatusNameValues = Enum.getNamesAndValues(LEASelfCertStatuses);
    leaSelfCertStatusNameValues.forEach(leaSelfCertStatus => {
      this[leaSelfCertStatus.value] = leaSelfCertStatus.name;
    });
  }
}
