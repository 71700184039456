import { EntityTypes } from './entity-types';

export class EntityTypeNames {
  [entityType: string]: string;

  constructor() {
    this[EntityTypes.Any] = 'Any';
    this[EntityTypes.CaliforniaDepartmentOfEducation] = 'California Department Of Education';
    this[EntityTypes.CharterSchool] = 'Charter School';
    this[EntityTypes.CountyOfficeOfEducation] = 'County Office Of Education';
    this[EntityTypes.JointPowersAgency] = 'Joint Powers Agency';
    this[EntityTypes.SchoolDistrict] = 'School District';
    this[EntityTypes.StateBoardOfEducation] = 'State Board Of Education';
    this[EntityTypes.Unknown] = 'Unknown';
  }
}
