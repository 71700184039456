import { Injectable, Input } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { Enum } from '../enum';
import { LayoutOrientation } from '../form.model';

@Injectable()
export class PrintContext {
  @Input()
  public loading: boolean;

  private callback: Function;

  public visible = false;
  public options: Array<SelectItem<string>>;
  public selectedOption: string;
  public showLayoutOrientation = false;
  public selectedLayoutOrientation: LayoutOrientation | null = null;
  public layoutOrientations: Array<SelectItem<LayoutOrientation>> =
    Enum.getValues<LayoutOrientation>(LayoutOrientation)
      .map(key => ({ value: LayoutOrientation[key], label: key }));

  public showPrintDialog(callback: Function) {
    this.callback = callback;
    this.loading = false;
    this.visible = true;
  }

  public closePrintDialog() {
    this.visible = false;
  }


  public print() {
    this.callback();
    this.closePrintDialog();
  }
}
