import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MenuModule } from 'primeng/menu';
import { ButtonModule } from 'primeng/button';
import { MenubarModule } from 'primeng/menubar';
import { ToolbarModule } from 'primeng/toolbar';
import { MegaMenuModule } from 'primeng/megamenu';
import { PanelMenuModule } from 'primeng/panelmenu';
import { SlideMenuModule } from 'primeng/slidemenu';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { SplitButtonModule } from 'primeng/splitbutton';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { TreeModule } from 'primeng/tree';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule } from '@angular/forms';
import { EditorModule } from 'primeng/editor';
import { TooltipModule } from 'primeng/tooltip';
import { ToggleButtonModule } from 'primeng/togglebutton';

import { SharedModule } from '../shared.module';

import { TopBarComponent } from './top/top-bar.component';
import { LeftMenuComponent } from './left/left-menu.component';
import { LeftSubMenuComponent } from './left/left-submenu.component';
import { UserMenuComponent } from './top/usermenu/user-menu.component';
import { HelpDeskService } from '../services/help-desk.service';
import { MessageModule } from 'primeng/message';

@NgModule({
  declarations: [
    TopBarComponent,
    LeftMenuComponent,
    LeftSubMenuComponent,
    UserMenuComponent
  ],
  imports: [
    ScrollPanelModule,
    CommonModule,
    MenuModule,
    ButtonModule,
    MenubarModule,
    ToolbarModule,
    MegaMenuModule,
    PanelMenuModule,
    SlideMenuModule,
    BreadcrumbModule,
    TieredMenuModule,
    SplitButtonModule,
    SharedModule,
    TreeModule,
    DropdownModule,
    InputTextModule,
    FormsModule,
    EditorModule,
    MessageModule,
    TooltipModule,
    ToggleButtonModule
  ],
  exports: [
    TopBarComponent,
    LeftMenuComponent,
    LeftSubMenuComponent,
    UserMenuComponent
  ]
  ,
  providers: [
    HelpDeskService
  ]
})
export class NavigationModule {
  constructor() { }
}
