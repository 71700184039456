import { Component, Input, ChangeDetectorRef } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { MenuItem } from 'primeng/api';
import { LeftMenuComponent } from './left-menu.component';

@Component({
  selector: '[app-left-submenu]',
  templateUrl: './left-submenu.component.html',
  animations: [
    trigger('children', [
      state('hiddenAnimated', style({
        height: '0px'
      })),
      state('visibleAnimated', style({
        height: '*'
      })),
      state('visible', style({
        height: '*',
        'z-index': 100
      })),
      state('hidden', style({
        height: '0px',
        'z-index': '*'
      })),
      transition('visibleAnimated => hiddenAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
      transition('hiddenAnimated => visibleAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ]
})
export class LeftSubMenuComponent {
  @Input() items: MenuItem[];
  @Input() visible: boolean;
  @Input() expanded: boolean = false;

  _parentActive: boolean;
  _reset: boolean;

  activeIndex: number;

  constructor(
    public leftMenu: LeftMenuComponent,
    private changeDetectorRef: ChangeDetectorRef) {
    this._parentActive = true;
  }

  ngOnInit(){
    this.setLeftMenuScrollToActiveItem();
    this.changeDetectorRef.detectChanges();
  }

  itemClick(event: Event, item: MenuItem, index: number): boolean {
    if (item.items)
      item.expanded = !item.expanded;

    // avoid processing disabled items
    if (item.disabled) {
      event.preventDefault();
      return true;
    }

    // activate current item and deactivate active sibling if any
    this.activeIndex = (this.activeIndex === index || !item.expanded) ? null : index;

    // execute command
    if (item.command)
      item.command({ originalEvent: event, item: item });

    // prevent hash change
    if (item.items || (!item.url && !item.routerLink)) {
      setTimeout(
        () => this.leftMenu.layoutMenuScrollerViewChild.moveBar(),
        450);
      event.preventDefault();
    }

    // hide menu
    if (!item.items) {
      if (this.leftMenu.isHorizontal())
        this.leftMenu.resetMenu = true;
      else
        this.leftMenu.resetMenu = false;

      this.leftMenu.overlayMenuActive = false;
      this.leftMenu.staticMenuMobileActive = false;
      this.leftMenu.menuHoverActive = !this.leftMenu.menuHoverActive;
    }

    return false;
  }

  onMouseEnter(index: number): void {
    if (this.leftMenu.menuHoverActive &&
      this.leftMenu.isHorizontal() &&
      !this.leftMenu.isMobile() &&
      !this.leftMenu.isTablet())
      this.activeIndex = index;
  }

  @Input() get reset(): boolean {
    return this._reset;
  }

  set reset(val: boolean) {
    this._reset = val;

    if (this._reset && this.leftMenu.isHorizontal())
      this.activeIndex = null;
  }

  @Input() get parentActive(): boolean {
    return this._parentActive;
  }

  set parentActive(val: boolean) {
    this._parentActive = val;
    if (!this._parentActive)
      this.activeIndex = null;
  }

  setLeftMenuScrollToActiveItem() {
    const activeItem = this.getActiveMenuItem();
    if (activeItem && document.getElementById(activeItem.id)) {
      const coordinate = document.getElementById(activeItem.id).offsetTop;
      this.leftMenu.setLeftMenuScrollTop(coordinate);
    }
  }

  private getActiveMenuItem(items?: Array<MenuItem>): MenuItem {
    const currItemURL = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
    items ||= this.items;

    if (items && items.length)
      for (const item of items)
      {
        const routes = item.routerLink as Array<string>;
        if (routes && routes.length && routes[routes.length - 1] == currItemURL)
          return item;

        const subItems = item.items;
        if(subItems && subItems.length) {
          const activeItem = this.getActiveMenuItem(subItems);
          if (activeItem) {
            item.expanded = true;
            return activeItem;
          }
        }
      }

    return null;
  }
}
