<div id="BatchTRCContent" class="sub-frame">
  <div class="formgrid grid">
    <div class="field col-12 pt-3">
      <label for="batchName" id="batchNameLabel">Batch Name</label>
      <input #batchName="ngModel" id="batchName" type="text" pInputText [(ngModel)]="results.name"
             required [pattern]="fileNamePattern">
    </div>
    <ng-container *ngIf="data.showOfficialSelection">
      <div class="field col-12 pt-3">
        <label for="batchExportTypeOfficial" id="batchExportTypeLabel">Export Type</label>
        <div class="field-radiobutton">
          <p-radioButton inputId="batchExportTypeOfficial"
                         id="batchExportTypeOfficial"
                         name="exportTypeGroup"
                         value="Official"
                         [(ngModel)]="exportTypeSelection"
                         (onClick)="onExportTypeSelection(true)"></p-radioButton>
          <label for="batchExportTypeOfficial">Official</label>
        </div>
        <div class="field-radiobutton">
          <p-radioButton inputId="batchExportTypeOther"
                         id="batchExportTypeOther"
                         name="batchExportTypeGroup"
                         value="Other"
                         [(ngModel)]="exportTypeSelection"
                         (onClick)="onExportTypeSelection(false)"></p-radioButton>
          <label for="batchExportTypeOther">Other</label>
        </div>
      </div>
    </ng-container>
    <div class="col-12 flex justify-content-end mt-5">
      <p-button id="cancel" styleClass="p-button-icon p-button-secondary mr-1" icon="pi pi-times"
                type="button" role="button"
                label="Cancel" arial-label="Cancel"
                (onClick)="cancel()">
      </p-button>
      <p-button id="create" styleClass="p-button-icon" icon="pi pi-check"
                type="button" role="button"
                label="Start" arial-label="Start"
                [disabled]="!!batchName.errors"
                (onClick)="createBatch()">
      </p-button>
    </div>
  </div>
</div>
