import { Injectable } from '@angular/core';
import { BatchJobTypes } from '../shared/batch-job-types';
import { BatchDialogData, BatchDialogResults, BatchJobApiRequest, IBatchJobParameters } from '../shared/batch.models';
import { BatchDialogComponent } from '../shared/batch-dialog/batch-dialog.component';
import { StatusTypes } from '../shared/status-types';
import { BatchService } from './batch.service';
import { DialogService } from 'primeng/dynamicdialog';
import { AppMessageService } from './app-message.service';
import { AppContext } from '../app.context';
import { NgMessage } from '../shared/ng-message';
import { MessageCodes } from '../shared/message-codes';

@Injectable({
  providedIn: 'root'
})
export class BatchDialogService {
  private readonly unknownErrorMessage: NgMessage;

  constructor(
    private readonly appContext: AppContext,
    private readonly batchService: BatchService,
    private readonly dialogService: DialogService,
    private readonly appMessageService: AppMessageService) {
    this.unknownErrorMessage = appContext.messages[MessageCodes.UnknownError];
  }

  createBatch<TBatchJobParameters extends IBatchJobParameters>(
    type: BatchJobTypes,
    header: string,
    successMessageCode: MessageCodes,
    createJobParameters: (dialogResults: BatchDialogResults) => TBatchJobParameters,
    dialogData: BatchDialogData = new BatchDialogData(),
    width: string = '33%',
    contentStyle: any = { "max-height": "620px", "overflow": "auto" },
    baseZIndex: number = 10000): void {
    const ref = this.dialogService.open(BatchDialogComponent, {
      data: dialogData,
      header: header,
      width: width,
      contentStyle: contentStyle,
      baseZIndex: baseZIndex
    });

    ref.onClose.subscribe((dialogResults: BatchDialogResults) => {
      if (!dialogResults)
        return;

      this.appMessageService.clear();

      const jobParameters = createJobParameters(dialogResults);

      const request = new BatchJobApiRequest({
        name: dialogResults.name,
        type: type,
        parameters: JSON.stringify(jobParameters)
      });

      const ngRequest = this.appContext.createRequest(request);

      this.batchService.createJob(ngRequest).subscribe(ngResponse => {
        if (!ngResponse)
          this.appMessageService.add({ severity: this.unknownErrorMessage.severityLevel.toLocaleString(), summary: this.unknownErrorMessage.text });
        else if (ngResponse.status === StatusTypes.Success) {
          const successMessage = this.appContext.messages[successMessageCode];
          this.appMessageService.add({ severity: successMessage.severityLevel.toLocaleString(), summary: successMessage.text });
        } else
          this.appMessageService.add({ severity: ngResponse.error.severityLevel.toLocaleString(), summary: ngResponse.error.failureReason });
      });
    });
  }
}
