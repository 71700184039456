<ng-template ngFor let-child let-i="index" [ngForOf]="items">
  <li [ngClass]="{'active-menuitem': activeIndex === i || child.expanded}" [class]="child.badgeStyleClass" *ngIf="child.visible !== false">
    <a [href]="child.url||'#'" (click)="itemClick($event,child,i)" (mouseenter)="onMouseEnter(i)"
       *ngIf="!child.routerLink" [ngClass]="child.styleClass" [id]="child.id"
       [attr.tabindex]="!visible ? '-1' : null" [attr.target]="child.target" [attr.aria-label]="'Toggle ' + child.label + ' Section'">
       <i [ngClass]="child.icon"></i>
      <span>{{child.label}}</span>
      <i class="pi pi-angle-down layout-submenu-toggler" *ngIf="child.items"></i>
      <span class="menuitem-badge" *ngIf="child.badge">{{child.badge}}</span>
    </a>

    <a (click)="itemClick($event,child,i)" (mouseenter)="onMouseEnter(i)" *ngIf="child.routerLink"
       [routerLink]="child.routerLink" routerLinkActive="active-menuitem-routerlink" [id]="child.id"
       [routerLinkActiveOptions]="{exact: true}" [attr.tabindex]="!visible ? '-1' : null" [attr.target]="child.target" [attr.aria-label]="'Toggle ' + child.label + ' Section'">
       <i [ngClass]="child.icon"></i>
      <span>{{child.label}}</span>
      <i class="pi pi-angle-down layout-submenu-toggler" *ngIf="child.items"></i>
      <span class="menuitem-badge" *ngIf="child.badge">{{child.badge}}</span>
    </a>
    <ul app-left-submenu [items]="child.items" *ngIf="child.items && child.items.length" [visible]="activeIndex === i" [reset]="reset" [parentActive]="activeIndex === i"
        [@children]="leftMenu.isHorizontal() ? activeIndex === i ? 'visible' : 'hidden' : activeIndex === i || child.expanded ? 'visibleAnimated' : 'hiddenAnimated'"></ul>
  </li>
</ng-template>
