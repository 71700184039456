import { User } from "./user.model";

export enum LockTypes {
  Submission = "Submission",
  Form = "Form"
}

export class SubmissionLockData {
  public submissionNumber: string;
  public lockData: LockData[] = [];
}

export class LockData {
  public lockType: LockTypes;
  public lockedBy: User;
  public locked: Date;
  public formInstanceId: string;
  public isLockedByCurrentUser: boolean;
}

export class LockFormRequest {
  constructor(
    public submissionNumber: string,
    public formInstanceId: string,
    public relatedInstanceIds: string[]) {

  }
}
