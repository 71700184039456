import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppContext } from '../app.context';
import { FilterSortPageRequest, FilterValuesRequest, FilterValuesResponse } from '../shared/grid.model';
import { NgRequest } from '../shared/ng-request';
import { NgResponse } from '../shared/ng-response';
import { HttpClientService } from './http-client.service';
import { HttpErrorHandler } from './http-error-handler';
import { BaseReportNoFilterSortResponse, BaseReportModel, BaseReportRequest, BaseReportResponse } from '../shared/base-report.model';
import { ExportTypes } from '../shared/export-types';

@Injectable()
export class ReportService extends HttpClientService {
  constructor(
    readonly appContext: AppContext,
    readonly httpClient: HttpClient,
    readonly httpErrorHandler: HttpErrorHandler) {
    super('ReportService', httpClient, httpErrorHandler);
  }

  fetchItems<TRequest extends BaseReportRequest, TResponse extends BaseReportResponse<BaseReportModel>, TModel extends BaseReportModel>(reportId: string, ngRequest: NgRequest<FilterSortPageRequest<TRequest>>): Observable<NgResponse<BaseReportResponse<BaseReportModel>>> {
    return this.postBody<FilterSortPageRequest<TRequest>, TResponse>('fetchItems', `api/Report/${reportId}/Items`, ngRequest);
  }

  fetchFilterValues<TRequest extends BaseReportRequest>(reportId: string, ngRequest: NgRequest<FilterValuesRequest<TRequest>>): Observable<NgResponse<FilterValuesResponse>> {
    return this.postBody<FilterValuesRequest<TRequest>, FilterValuesResponse>('fetchFilterValues', `api/Report/${reportId}/FilterValues`, ngRequest);
  }

  fetchData<TRequest extends BaseReportRequest, TResponse extends BaseReportNoFilterSortResponse>(reportId: string, ngRequest: NgRequest<TRequest>, dataEndpoint: string): Observable<NgResponse<TResponse>> {
    return this.postBody<TRequest, TResponse>('fetchData', `api/Report/${reportId}/${dataEndpoint}`, ngRequest);
  }

  export<TRequest>(type: ExportTypes, reportId: string, ngRequest: NgRequest<TRequest>): Observable<HttpResponse<Blob>> {
    return this.postBodyWithBlobResponse<TRequest>('export', `api/Report/${reportId}/${type}`, ngRequest);
  }
}
