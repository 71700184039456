import {
  AfterContentInit,
  AfterViewInit,
  Component, ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MenuItem, PrimeTemplate } from 'primeng/api';
import { ScrollPanel } from 'primeng/scrollpanel';
import { fieldof } from 'src/app/shared/string-operations';
import { AppContext } from '../../app.context';

@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  encapsulation: ViewEncapsulation.None
})
export class LeftMenuComponent implements AfterContentInit, AfterViewInit {
  @ViewChild('layoutMenuScroller', { static: false }) layoutMenuScrollerViewChild: ScrollPanel;

  @Input() model: Array<MenuItem>;
  @Input() reset: boolean;
  @Input() filterPlaceholder: string;

  @Output() slimMenuActive: EventEmitter<boolean>;
  @Output() slimMenuAnchorEmitter: EventEmitter<boolean>;

  headerButtons: TemplateRef<any>;
  headerContent: TemplateRef<any>;

  @ContentChildren(PrimeTemplate) templates: QueryList<PrimeTemplate>;

  lightMenu = true;
  menuMode = 'slim';
  menuHoverActive: boolean;
  resetMenu: boolean;
  overlayMenuActive: boolean;
  staticMenuMobileActive: boolean;
  slimMenuAnchor = false;
  activeMenu = true;

  filterInput: string;
  allItemsHidden: boolean;

  constructor(readonly appContext: AppContext) {
    this.slimMenuAnchor = true;
    this.slimMenuActive = new EventEmitter<boolean>();
    this.overlayMenuActive = true;
    this.slimMenuAnchorEmitter = new EventEmitter<boolean>();
    this.allItemsHidden = false;
  }

  ngAfterContentInit(): void {
    this.templates.forEach((item) => {
      switch (item.getType()) {
        case fieldof<LeftMenuComponent>('headerButtons'):
          this.headerButtons = item.template;
          break;
        case fieldof<LeftMenuComponent>('headerContent'):
          this.headerContent = item.template;
          break;
      }
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.layoutMenuScrollerViewChild.moveBar();
    }, 100);
    this.allItemsHidden = this.areAllItemsHidden();
  }

  filterMenuItems(event) {
    const normalizedFilterInput = this.filterInput.toLowerCase();

    this.model = this.filter(normalizedFilterInput, this.model, null);
  }

  filter(data: string, list: Array<MenuItem>, parent: any) {
    let foundMatch = false;

    // recursively loop through a tree-like object
    // starting with lowest children and moving up
    for (let index = 0; index < list.length; index++)
      if (!list[index].items)
        if (!list[index].label.toLowerCase().includes(data))
          list[index].visible = false;
        else //match found
          list[index].visible = true;
      else
        this.filter(data, list[index].items as Array<MenuItem>, list[index]);

    // after a list has been completed, loop
    // it again to check if foundMatch is true
    for (let index = 0; index < list.length; index++)
      if (list[index].visible) {
        foundMatch = true;
        break;
      }

    // no match found = all child items are hidden
    // if no match found, check for parent and
    // check if parent matches.else, hide parent
    if (!foundMatch && parent)
      if (!parent.label.toLowerCase().includes(data))
        parent.visible = false;
      else
        parent.visible = true;
    else if (parent)
      // used for showing after clearing filter
      // in the event that a parent was hidden
      parent.visible = true;

    return list;
  }

  // used to detect if all objects in our given submenu items
  // are hidden (happens when filter produces zero results)
  private areAllItemsHidden(items?: Array<MenuItem>): boolean {
    items = items || this.model;

    if (!items || !items.length)
      return false;

    let result = true;

    for (const item of items) {
      if (item.visible || item.visible === null || item.visible === undefined)
        return false;

      if (item.items)
        result = this.areAllItemsHidden(item.items);

      if (!result)
        // this check will break us out of all
        // loops when item is found recursively
        return false;
    }

    return result;
  }

  onMenuClick(event) {
    if (!this.isHorizontal())
      setTimeout(
        () => this.layoutMenuScrollerViewChild.moveBar(),
       450);
  }

  isHorizontal() {
    return this.menuMode === 'horizontal';
  }

  isMobile() {
    return window.innerWidth < 1281;
  }

  isDesktop() {
    return window.innerWidth > 1280;
  }

  isTablet() {
    const width = window.innerWidth;
    return width <= 1280 && width > 640;
  }

  hideOverlayMenu() {
    this.overlayMenuActive = false;
    this.staticMenuMobileActive = false;
  }

  hideSlimMenu() {
    this.slimMenuActive.emit(false);

    this.staticMenuMobileActive = false;
  }

  isSlim() {
    return this.menuMode === 'slim';
  }

  onMenuMouseEnter(event) {
    if (this.isSlim()) {
      this.slimMenuActive.emit(true);
    }

    this.activeMenu = true;
  }

  onMenuMouseLeave(event) {
    if (this.isSlim()) {
      this.slimMenuActive.emit(false);
    }

    if (!this.slimMenuAnchor) {
      this.activeMenu = false;
    }
  }

  onAnchorClick(event) {

    this.slimMenuAnchor = !this.slimMenuAnchor;
    this.slimMenuAnchorEmitter.emit(this.slimMenuAnchor);
  }

  setLeftMenuScrollTop(coordinate: number) {
    setTimeout(() => {
      this.layoutMenuScrollerViewChild.scrollTop(coordinate);
    }, 100);
  }
}
