export enum ColumnCodes {
  Any = 'Any',
  Unknown = 'Unknown',
  BB = 'BB',
  BE = 'BE',
  BA = 'BA',
  IO = 'IO',
  IB = 'IB',
  IA = 'IA',
  IP = 'IP'
}
