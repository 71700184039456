import { CharterTypes } from './charter-types';

export class CharterTypeNames {
  [charterType: string]: string;

  constructor() {
    this[CharterTypes.CountyOfficeOfEducation] = 'County Office of Education';
    this[CharterTypes.SchoolDistrict] = 'School District';
    this[CharterTypes.JointPowersAgency] = 'Joint Powers Agency';
  }
}
