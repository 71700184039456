export class EntityTypeRankNames {
  [entityTypeRank: number]: string;

  constructor() {
    this[1] = 'California Department Of Education';
    this[2] = 'State Board Of Education';
    this[3] = 'County Office Of Education';
    this[4] = 'School District';
    this[5] = 'Joint Powers Agency';
    this[6] = 'Charter School';
  }
}
