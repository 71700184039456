import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { HttpClientService } from './http-client.service';
import { HttpErrorHandler } from './http-error-handler';

import { NgRequest } from '../shared/ng-request';
import { NgResponse } from '../shared/ng-response';
import { FilterSortPageRequest, FilterSortPageResponse, FilterValuesRequest, FilterValuesResponse } from '../shared/grid.model';
import { UserElementDataRecordsRequest, UserElementDataRecordsResponse, UserGLDataRecordsRequest, UserGLDataRecordsResponse } from '../shared/user-data.model';

@Injectable()
export class UserDataService extends HttpClientService {
  constructor(
    readonly httpClient: HttpClient,
    readonly httpErrorHandler: HttpErrorHandler) {
    super('UserDataService', httpClient, httpErrorHandler);
  }

  fetchGLDataRecords(request: NgRequest<FilterSortPageRequest<UserGLDataRecordsRequest>>): Observable<NgResponse<UserGLDataRecordsResponse>> {
    return this.postBody<FilterSortPageRequest<UserGLDataRecordsRequest>, UserGLDataRecordsResponse>(
      'fetchGLDataRecords',
      `api/UserData/GL/Records`,
      request);
  }

  fetchGLDataFilterValues(
    request: NgRequest<FilterValuesRequest<UserGLDataRecordsRequest>>): Observable<NgResponse<FilterValuesResponse>> {
    return this.postBody<FilterValuesRequest<UserGLDataRecordsRequest>, FilterValuesResponse>(
      'fetchGLDataFilterValues',
      `api/UserData/GL/FilterValues`,
      request);
  }

  fetchElementDataRecords(request: NgRequest<FilterSortPageRequest<UserElementDataRecordsRequest>>): Observable<NgResponse<UserElementDataRecordsResponse>> {
    return this.postBody<FilterSortPageRequest<UserElementDataRecordsRequest>, UserElementDataRecordsResponse>(
      'fetchElementDataRecords',
      `api/UserData/Element/Records`,
      request);
  }

  fetchElementDataFilterValues(
    request: NgRequest<FilterValuesRequest<UserElementDataRecordsRequest>>): Observable<NgResponse<FilterValuesResponse>> {
    return this.postBody<FilterValuesRequest<UserElementDataRecordsRequest>, FilterValuesResponse>(
      'fetchElementDataFilterValues',
      `api/UserData/Element/FilterValues`,
      request);
  }
}
